<template>
  <div class="flex flex-col items-center justify-center h-full mt-10">
    <header class="mb-20 text-center">
      <h1 class="text-base leading-6 text-black mb-7.5">
        {{ status }} - {{ title }}
      </h1>
      <p
        v-if="description"
        class="max-w-xl mx-auto mb-4 text-xs leading-4 text-center text-bo-gray-400">
        {{ description }}
      </p>
    </header>
    <div class="mb-15">
      <template
        v-if="status === 403">
        <img
          v-if="type === 'subscription'"
          src="../../../../assets/img/backoffice/errors/403_subscription.svg"
          alt="">
        <img
          v-else-if="type === 'permission'"
          src="../../../../assets/img/backoffice/errors/403_permission.svg"
          alt="">
      </template>
      <img
        v-else-if="status === 404"
        src="../../../../assets/img/backoffice/errors/404.svg"
        alt="">
      <img
        v-else-if="status === 500"
        src="../../../../assets/img/backoffice/errors/500.svg"
        alt="">
    </div>
    <div class="w-[650px] mx-auto h-px mb-10 bg-dsh-gray-400" />
    <div>
      <sm-button
        color="primary"
        size="lg"
        :title="$t('errors.new.back_to_home')"
        :href="backRoute" />
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  status: { type: Number, required: true, default: 404 },
  backRoute: { type: String, required: true, default: "/" },
  type: { type: String, required: false, default: "subscription" },
});

const title = computed(() => {
  return {
    403: window.trans(`errors.new.403.${props.type}.title`),
    404: window.trans("errors.new.404.title"),
    500: window.trans("errors.new.500.title"),
  }[props.status];
});

const description = computed(() => {
  return {
    403: window.trans(`errors.new.403.${props.type}.description`),
    404: window.trans("errors.new.404.description"),
    500: window.trans("errors.new.500.description"),
  }[props.status];
});
</script>
