<template>
  <div>
    <sm-button
      :icon="icon"
      :title="title"
      :size="icon"
      :color="color"
      :disabled="disabled"
      @click="onConfirm" />
    <sm-confirm-dialog
      title="Confirm title"
      :group="name" />
  </div>
</template>

<script setup>
import { useConfirm } from "primevue/useconfirm";

const props = defineProps({
  name: { type: String, required: true, default: null },
  title: { type: String, required: false, default: null },
  size: { type: String, required: false, default: "icon" },
  icon: { type: String, required: false, default: null },
  color: { type: String, required: false, default: null },
  disabled: { type: Boolean, required: false, default: false },
  confirmTitle: { type: String, required: true, default: null },
});

const emit = defineEmits(["onAccept"]);

const conf = useConfirm();

const onConfirm = () => {
  conf.require({
    group: props.name,
    header: props.confirmTitle ? props.confirmTitle : window.trans("generic.are_you_sure"),
    accept: () => emit("onAccept")
  });
};
</script>
