<template>
  <settings-layout>
    <in-head :title="$t('appointments.settings.page_title')" />

    <form @submit.prevent="onSubmit()">
      <sm-card
        :header-title="$t('appointments.settings.page_title')">
        <sm-input
          v-model="form.default_price"
          type="number"
          name="default_price"
          :label="$t('appointments.settings.default_price.label')"
          :help="$t('appointments.settings.default_price.help')"
          inline
        >
          <template #append>
            Ft
          </template>
        </sm-input>
        <sm-radio-group
          v-model="form.half_hour"
          name="half_hour"
          :items="half_hour_options"
          :label="$t('appointments.settings.half_hour.label')"
          :help="$t('appointments.settings.half_hour.help')"
          inline
        />
        <sm-radio-group
          v-model="form.auto_placement"
          name="auto_placement"
          :items="auto_placement_options"
          break-lines
          :label="$t('appointments.settings.auto_placement.label')"
          :help="$t('appointments.settings.auto_placement.help')"
          inline
        />
        <sm-input
          v-model="form.booking_cancellation_time"
          type="number"
          name="booking_cancellation_time"
          inline
          :label="$t('appointments.settings.booking_cancellation_time.label')"
          :help="$t('appointments.settings.booking_cancellation_time.help')"
        >
          <template #append>
            {{ $t('generic.hours_before') }}
          </template>
        </sm-input>
        <sm-radio-group
          v-model="form.retrospective_booking"
          name="retrospective_booking"
          :items="retrospective_booking_options"
          :label="$t('appointments.settings.retrospective_booking.label')"
          :help="$t('appointments.settings.retrospective_booking.help')"
          inline
        />
        <sm-input
          v-model="form.booking_buffer_for_services"
          type="number"
          name="booking_buffer_for_services"
          :label="$t('appointments.settings.booking_buffer_for_services.label')"
          :help="$t('appointments.settings.booking_buffer_for_services.help')"
          inline
        >
          <template #append>
            {{ $t('generic.minute') }}
          </template>
        </sm-input>
        <sm-input
          v-model="form.advance_booking_for_days"
          type="number"
          name="advance_booking_for_days"
          :label="$t('appointments.settings.advance_booking_for_days.label')"
          :help="$t('appointments.settings.advance_booking_for_days.help')"
          inline
        >
          <template #append>
            {{ $t('generic.day') }}
          </template>
        </sm-input>
        <sm-select
          v-model="form.ticket_refund_method"
          :label="$t('appointments.settings.ticket_refund_method.label')"
          :help="$t('appointments.settings.ticket_refund_method.help')"
          :options="refundOptions"
          inline
        />
        <sm-radio-group
          v-model="form.allow_booking_with_empty_ticket"
          name="allow_booking_with_empty_ticket"
          :items="allow_booking_with_empty_ticket_options"
          :label="$t('appointments.settings.allow_booking_with_empty_ticket.label')"
          :help="$t('appointments.settings.allow_booking_with_empty_ticket.help')"
          :help-2="$t('appointments.settings.allow_booking_with_empty_ticket.help2')"
          inline
        />
        <sm-radio-group
          v-model="form.refund_ticket_purchased_at_booking"
          name="refund_ticket_purchased_at_booking"
          :items="refund_ticket_purchased_at_booking_options"
          :label="$t('appointments.settings.refund_ticket_purchased_at_booking.label')"
          :help="$t('appointments.settings.refund_ticket_purchased_at_booking.help')"
          inline
        />

        <div class="sm-form-group sm-row sm-form-inline">
          <div class="w-full sm-col lg:w-4/12">
            <sm-label :label="$t('appointments.settings.enabled_appointment_lengths.label')" />
          </div>
          <div class="w-full sm-col lg:w-8/12">
            <div class="flex">
              <sm-checkbox
                v-for="item in appointmentLengths"
                :key="item"
                :name="item"
                :text="$t('appointments.settings.enabled_appointment_lengths.text', {minute: item})"
                :value="activeAppointmentLengths?.includes(item)"
                @update:model-value="updateAppointmentLengths(item)"
              />
            </div>
          </div>
        </div>

        <sm-input
          v-model="form.booking_title_on_invoice"
          type="text"
          name="booking_title_on_invoice"
          :label="$t('appointments.settings.booking_title_on_invoice.label')"
          :help="$t('appointments.settings.booking_title_on_invoice.help')"
          inline
        />
      </sm-card>

      <sm-card
        :header-title="$t('appointments.settings.notifications')">
        <sm-input
          v-model="form.appointment_notification_email"
          name="appointment_notification_email"
          :label="$t('notifications.settings.appointment_notification_email.label')"
          :help="$t('notifications.settings.appointment_notification_email.help')"
        />
      </sm-card>

      <sm-card
        :header-title="$t('appointments.settings.public_calendar')">
        <sm-input
          v-model="form.allowed_domains"
          name="allowed_domains"
          :label="$t('appointments.settings.allowed_domains.label')"
          :help="$t('appointments.settings.allowed_domains.help')"
        />
        <sm-select
          v-model="form.public_calendar_display_mode"
          :label="$t('appointments.settings.public_calendar_display_mode.label')"
          :help="$t('appointments.settings.public_calendar_display_mode.help')"
          :options="calendarOptions"
        />
        <sm-radio-group
          v-model="form.public_calendar_skip_empty"
          name="public_calendar_skip_empty"
          :items="public_calendar_skip_empty_options"
          :label="$t('appointments.settings.public_calendar_skip_empty.label')"
          :help="$t('appointments.settings.public_calendar_skip_empty.help')"
        />
        <sm-label :label="$t('appointments.settings.public_calendar_code.label')" />
        <sm-copy-code :code="calendarScript" />
      </sm-card>
      <sm-card>
        <sm-button
          preset="save"
          :loading="form.processing"
          @click="onSubmit" />
      </sm-card>
    </form>
  </settings-layout>
</template>

<script setup>
import { useFormatArrayToTrueFalseObjects, useFormatSelect } from "@/inertia/composables/global";
import { useForm, usePage } from "@inertiajs/vue3";

// default variables
const page = usePage();

// component props
const props = defineProps({
  settings: { type: Object, required: false, default: null },
  notificationSettings: { type: Object, required: false, default: null },
  refundOptions: { type: Object, required: false, default: () => {} },
  calendarOptions: { type: Object, required: false, default: () => {} },
  calendarAsset: { type: String, required: false, default: "" },
  appointmentLengths: { type: Object, required: false, default: () => {} },
});

// select and radio group options
const half_hour_options = ref([
  {
    id: "half-hourly",
    text: "Félórás"
  },{
    id: "not-half-hourly",
    text: "Órás"
  }
]);

const auto_placement_options = ref([
  {
    id: "auto-placement",
    text: "A rendszer automatikusan osztja be a szabad helyeket"
  },{
    id: "not-auto-placement",
    text: "A felhasználók szabadon választhatnak helyszínt"
  }
]);

const retrospective_booking_options = useFormatArrayToTrueFalseObjects(["retrospective-booking", "not-retrospective-booking"]);

const allow_booking_with_empty_ticket_options = useFormatArrayToTrueFalseObjects(["allow_booking_with_empty_tickets__enabled", "allow_booking_with_empty_tickets__disabled"]);

const refund_ticket_purchased_at_booking_options = useFormatArrayToTrueFalseObjects(["refund_ticket_purchased_at_booking_yes", "refund_ticket_purchased_at_booking_no"]);

const public_calendar_skip_empty_options = useFormatArrayToTrueFalseObjects(["public_calendar_skip_empty_yes", "public_calendar_skip_empty_no"]);

const refundOptions = computed(() => {
  return useFormatSelect(props.refundOptions);
});

const calendarOptions = computed(() => {
  return useFormatSelect(props.calendarOptions);
});

// form data
const form = useForm({
  default_price: props.settings.default_price,
  half_hour: props.settings.booking_interval,
  auto_placement: props.settings.auto_placement ? auto_placement_options.value[0].id : auto_placement_options.value[1].id,
  booking_cancellation_time: props.settings.cancellation_time,
  retrospective_booking: props.settings.retrospective_booking,
  booking_buffer_for_services: props.settings.booking_buffer_for_services,
  advance_booking_for_days: props.settings.advance_bookings_in_days,
  ticket_refund_method: refundOptions.value.find(data => data.id === props.settings.ticket_refund_method),
  allow_booking_with_empty_ticket: props.settings.allow_booking_with_empty_ticket,
  refund_ticket_purchased_at_booking: props.settings.refund_ticket_purchased_at_booking,
  allowed_domains: props.settings.public_calendar_allowed_domains.join(","),
  appointment_notification_email: props.notificationSettings.appointment_notification_email,
  public_calendar_display_mode: calendarOptions.value.find(data => data.id === props.settings.public_calendar_display_mode),
  enabled_appointment_lengths: props.settings.enabled_appointment_lengths,
  public_calendar_skip_empty: props.settings.public_calendar_skip_empty,
  booking_title_on_invoice: props.settings.booking_title_on_invoice,
});

// calendar script code transformations
const calendarScript = computed(() => {
  return "&lt;script src=\""+ props.calendarAsset +"\" data-club=\""+page.props.sportmate.club.id+"\" id=\"smc-calendar\"&gt;&lt;/script&gt;";
});

const activeAppointmentLengths = ref(form.enabled_appointment_lengths || []);

const updateAppointmentLengths = (length) => {
  if (activeAppointmentLengths.value.includes(length)) {
    activeAppointmentLengths.value = activeAppointmentLengths.value.filter(e => e !== length);
  } else {
    activeAppointmentLengths.value.push(length);
  }
};

// form submit
const onSubmit = () => {
  form
    .transform((data) => ({
      ...data,
      half_hour: data.half_hour === "half-hourly" ? true : false,
      auto_placement: data.auto_placement === "auto-placement" ? true : false,
      ticket_refund_method: data.ticket_refund_method?.id,
      public_calendar_display_mode: data.public_calendar_display_mode?.id,
      enabled_appointment_lengths: activeAppointmentLengths.value
    }))
    .post(page.url);
};

// provide form for errors
provide("form", form);
</script>
