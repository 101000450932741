<template>
  <div>
    <sm-card
      no-padding
      :header-title="$t('dashboard.sold_tickets.title')"
      class="!mb-0 !overflow-visible"
      v2
    >
      <template #headerActions>
        <div class="relative flex items-center space-x-2">
          <span v-if="!((today == dateRange.start) && (today == dateRange.end))">
            <button
              type="button"
              class="text-sm sm-link lh-0"
              @click="filterToday">{{ $t('dashboard.today') }}</button>
            <span class="p-1 text-bo-gray-400"> | </span>
          </span>
          <button
            type="button"
            class="text-sm cursor-pointer "
            @click="toggleDatePicker">
            <span v-if="dateRange.start === dateRange.end">{{ useDateFormat(dateRange.start) }}</span>
            <span v-else>{{ useDateFormat(dateRange.start) }} - {{ useDateFormat(dateRange.end) }}</span>
            <i class="ml-1 icon ion-ios-arrow-down" />
          </button>
          <div v-auto-animate>
            <div
              v-if="datePicker"
              ref="datePickerRef"
              class="absolute top-3 right-0 p-4 bg-white border rounded-lg min-w-[300px] z-50">
              <sm-date-range-picker
                v-model="dateRange"
                class="mb-0"
                @update:modelValue="loadData">
                <!-- <template #append>
                  <calendar-icon class="w-6" />
                </template> -->
              </sm-date-range-picker>
            </div>
          </div>

          <button
            type="button"
            class="sm-link text-lg leading-[0]"
            @click="loadData">
            <rotate-cw-icon
              class="w-4"
              stroke-width="2.5" />
          </button>
        </div>
      </template>
      <div v-auto-animate>
        <div v-if="loaded">
          <sm-table
            responsive
            :table-data="bestSellingTicketsTableData"
            table-heading-key="dashboard.sold_tickets.table"
            :column-classes="{soldAmount: 'w-[130px]', soldAmountPercentage: 'w-[130px]', price: 'w-[130px]'}"
            :header-classes="{soldAmount: 'text-right', soldAmountPercentage: 'text-right', price: 'w-[130px] text-right'}"
            :table-columns="bestSellingTicketsTableColumns">
            <template #name="slotProps">
              <a :href="`${$page.props.routes.seasonTickets}/${slotProps.data?.id}`">
                {{ slotProps.data?.name }}
              </a>
            </template>
            <template #soldAmount="slotProps">
              <div class="flex items-center justify-end">
                {{ slotProps.data.soldAmount }}
                <InfoIcon
                  class="w-3 ml-1 cursor-pointer text-bo-blue"
                  @click="openSalesModal(slotProps.data)" />
              </div>
            </template>
            <template #soldAmountPercentage="slotProps">
              <span class="block text-right">
                {{ slotProps.data.soldAmountPercentage }}%
              </span>
            </template>
            <template #price="slotProps">
              <span class="block text-right">
                {{ usePriceFormat(slotProps.data.price) }}
              </span>
            </template>
          </sm-table>
        </div>
        <div
          v-else
          class="flex items-center justify-center w-full h-10">
          <vue-feather
            type="rotate-cw"
            class="animate-spin"
            size="18px"
          />
        </div>
      </div>
    </sm-card>
    <sm-modal
      :modal-is-visible="modalOpen"
      :modal-title="$t('dashboard.sold_tickets.modal.title')"
      @toggleModalVisibility="toggleModal">
      <sm-table
        responsive
        bordered
        :table-data="ticketTableData"
        table-heading-key="dashboard.sold_tickets.modal.table"
        :table-columns="ticketTableColumns">
        <template #name="slotProps">
          <Link :href="slotProps.data.user_url">
            {{ slotProps.data.name }}
          </Link>
        </template>
        <template #created_at="slotProps">
          <Link :href="slotProps.data.transaction_url">
            {{ useDateFormat(slotProps.data.created_at) }}
          </Link>
        </template>
      </sm-table>
    </sm-modal>
  </div>
</template>

<script setup>
import { useDateFormat, usePriceFormat } from "@/inertia/composables/global";
import { CalendarIcon, ChevronDownIcon, RotateCwIcon } from "vue-feather-icons";
import { Link, usePage } from "@inertiajs/vue3";
import axios from "axios";
import debounce from "lodash/debounce";
import moment from "moment";
import { onMounted, ref, watch } from "vue";
import { InfoIcon } from "vue-feather-icons";
import { useToast } from "vue-toastification";
import { onClickOutside } from "@vueuse/core";

const page = usePage();

const modalOpen = ref(false);
const toggleModal = () => modalOpen.value = !modalOpen.value;

const selectedTicket = ref(null);
const today = ref(moment().format("YYYY-MM-DD"));
const pagination = ref({});
const search = ref(null);
const loaded = ref(false);
const dateRange = ref(ref({ start: "2025-01-01", end: "2025-01-30" }));

const toast = useToast();

const searchCallback = debounce(() => {
  loadData();
}, 250);

watch(search, () => {
  searchCallback();
});

const bestSellingTicketsTableColumns = ref(["name", "soldAmount", "soldAmountPercentage", "price"]);
const bestSellingTicketsTableData = ref(null);


const ticketTableColumns = ref(["name", "created_at"]);
const ticketTableData = ref(null);

const loadData = async (pageIndex = 1) => {
  loaded.value = false;
  try {
    const url = makeUrl(pageIndex);
    const response = await axios.get(url);
    const meta = response.data.meta;

    bestSellingTicketsTableData.value = response.data.data;
    if (!dateRange.value.start) {
      dateRange.value.start = meta.from_date;
    }
    if (!dateRange.value.end) {
      dateRange.value.end = meta.to_date;
    }
    pagination.value = meta;
    loaded.value = true;
  } catch (errors) {
    toast.error(errors.message);
    console.error(errors);
  }
};

const makeUrl = (pageIndex) => {
  let url = `${page.props.routes.bestSellingTickets}?page=${pageIndex}`;
  if (dateRange.value.start) {
    url += `&from=${useDateFormat(dateRange.value.start, "form")}`;
  }
  if (dateRange.value.end) {
    url += `&to=${useDateFormat(dateRange.value.end, "form")}`;
  }
  if (search.value && search.value.length > 0) {
    url += `&search=${encodeURIComponent(search.value)}`;
  }
  return url;
};

const makeDetailsUrl = (ticket) => {
  let url = `${page.props.routes.bestSellingTicketsDetails}?ticket=${ticket.id}`;
  if (dateRange.value.start) {
    url += `&from=${useDateFormat(dateRange.value.start, "form")}`;
  }
  if (dateRange.value.end) {
    url += `&to=${useDateFormat(dateRange.value.end, "form")}`;
  }
  return url;
};

const filter = () => {
  searchCallback();
};

const filterToday = () => {
  dateRange.value.start = today.value;
  dateRange.value.end = today.value;
  filter();
};

const openSalesModal = async (ticket) => {
  selectedTicket.value = ticket;
  const response = await axios.get(makeDetailsUrl(ticket));
  ticketTableData.value = response.data.data;

  toggleModal();
  // nextTick(() => window.toggleSmModal("#best-selling-ticket-sales-modal"));
};

const datePicker = ref(false);
const datePickerRef = ref();
const toggleDatePicker = () => {
  datePicker.value = !datePicker.value;
};

onClickOutside(datePickerRef, () => {
  if (datePickerRef.value) {
    datePicker.value = !datePicker.value;
  }
});

onMounted(loadData);
</script>
