<template>
  <sm-card :header-title="$t('customer_groups.show.others.title')">
    <template #headerActions>
      <div class="flex items-center space-x-1">
        <sm-button
          v-if="$page.props.permissions.canUpdate"
          preset="edit-icon"
          old-link
          :href="$page.props.routes.edit" />
        <customer-group-invoices-modal />
      </div>
    </template>

    <div class="space-y-2">
      <template
        v-for="data in othersData"
        :key="data"
      >
        <div
          v-if="data.visible"
          class="items-center sm-row">
          <div
            v-if="!data.full"
            class="w-full sm-col lg:w-4/12">
            <p>
              {{ data.title }}:
            </p>
          </div>
          <div
            class="w-full sm-col "
            :class="data.full ? '' : 'lg:w-8/12'">
            <sm-alert
              :color="data.full ? 'primary' : 'light'"
              no-margin>
              {{ data.data }}
            </sm-alert>
          </div>
        </div>
      </template>
    </div>
  </sm-card>
</template>

<script setup>
import { useDateFormat } from "@/inertia/composables/global";
import { usePage } from "@inertiajs/vue3";
import CustomerGroupInvoicesModal from "./CustomerGroupInvoicesModal.vue";

const page = usePage();

const othersData = ref([
  {
    title: window.trans("customer_groups.show.others.data.created_at"),
    data: useDateFormat(page.props.others.created_at, "long"),
    visible: true
  },
  {
    title: window.trans("customer_groups.show.others.data.note"),
    data: page.props.others.note,
    visible: true,
  },
  {
    title: window.trans("customer_groups.show.others.data.contact"),
    data: page.props.others.contact,
    visible: page.props.others.contact,
  },
  {
    title: window.trans("customer_groups.show.others.data.is_employee_group"),
    data: window.trans("customer_groups.show.others.data.is_employee_group"),
    visible: page.props.others.is_employee_group,
    full: true
  }
]);
</script>
