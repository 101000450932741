<template>
  <div>
    <In-Head :title="$t('customer_groups.show.title', {name: $page.props.customerGroup.name})" />

    <sub-header :title="$t('customer_groups.show.title', {name: $page.props.customerGroup.name})">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
      <template #actions>
        <sell-product-modal
          v-if="pageSaleModal && $page.props.permissions.canSellProducts"
          :search-product-route="$page.props.routes.searchProducts"
          :warehouses="pageSaleModal.warehouses"
          :active-warehouse="pageSaleModal.activeWarehouse"
          :customer="$page.props.customerGroup.userId"
          :price-calculator-route="$page.props.routes.calculatePrice"
          :open-cash-registers="pageSaleModal.openCashRegisters"
          :user-can-select-cash-register="pageSaleModal.permissions.userCanSelectCashRegister"
          :trigger-modal="triggerSellProductModal"
          :page-sale-modal="pageSaleModal"
          :page-permissions="$page.props.permissions"
        />
        <sm-toggle-button
          :title="$t('customer_groups.show.reports.title')"
          :options="toggleButtonOptions" />
      </template>
    </sub-header>

    <div class="sm-row">
      <div class="w-full mb-4 sm-col lg:w-3/12">
        <customer-group-profile-box />
        <sm-card>
          <customer-discounts :page-discounts="pageDiscounts" />
        </sm-card>
      </div>
      <div class="w-full sm-col lg:w-9/12">
        <div class="sm-row">
          <div class="w-full mb-4 sm-col lg:w-6/12">
            <customer-tickets
              v-if="$page.props.permissions.canListTickets"
              :page-tickets="pageTickets"
              @openSellProductModal="openSellProductModal" />
          </div>
          <div class="w-full mb-4 sm-col lg:w-6/12">
            <customer-group-others />
          </div>
          <div class="w-full sm-col">
            <customer-group-members />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import SellProductModal from "@/inertia/pages/customers/show/_modals/SellProductModal.vue";
import CustomerDiscounts from "@/inertia/pages/customers/show/_partials/CustomerDiscounts.vue";
import CustomerTickets from "@/inertia/pages/customers/show/_partials/CustomerTickets.vue";
import { router, usePage } from "@inertiajs/vue3";
import CustomerGroupMembers from "./_partials/CustomerGroupMembers.vue";
import CustomerGroupOthers from "./_partials/CustomerGroupOthers.vue";
import CustomerGroupProfileBox from "./_partials/CustomerGroupProfileBox.vue";

const page = usePage();

const pageSaleModal = ref(null);
const pageTickets = ref(null);
const pageDiscounts = ref(null);

onMounted(() => {
  pageSaleModal.value = null;
  pageTickets.value = null;
  pageDiscounts.value = null;

  router.reload({
    only: ["discounts", "saleModal", "tickets", "members", "deposit"],
    onSuccess: (page) => {
      pageSaleModal.value = page.props.saleModal;
      pageTickets.value = page.props.tickets;
      pageDiscounts.value = page.props.discounts;
    },
  }, {
    preserveState: true,
  });
});

watch(() => page.props.tickets, (tickets) => {
  pageTickets.value = tickets;
});

const triggerSellProductModal = ref(false);

const openSellProductModal = () => {
  triggerSellProductModal.value = !triggerSellProductModal.value;
};

const toggleButtonOptions = ref([
  {
    label: window.trans("customer_groups.show.reports.options.sold_tickets"),
    to: page.props.routes.reports.sold_tickets,
  },
  {
    label: window.trans("customer_groups.show.reports.options.customer_group_checkins"),
    to: page.props.routes.reports.customer_group_checkins,
  },
  {
    label: window.trans("customer_groups.show.reports.options.customer_special_cards"),
    to: page.props.routes.reports.customer_special_cards,
  },
]);
</script>
