<template>
  <sm-card
    :header-title="$t('Kasszában lévő készpénz')"
    :header-sub-title="$t('reports.money_in_cash_register.cash_tooltip')"
    class="!mb-0 h-full"
    v2
  >
    <info-card
      large
      :data="{
        color: 'teal',
        icon: 'dollar-sign',
        data: cashInPayBox,
      }" />
  </sm-card>
</template>

<script setup>
import { usePage } from "@inertiajs/vue3";
import { usePriceFormat } from "@/inertia/composables/global";
import InfoCard from "@/inertia/pages/reports/_partials/InfoCard.vue";

const page = usePage();

const cashInPayBox = computed(() => {
  return usePriceFormat(page.props.cashInPayBox || 0);
});
</script>
