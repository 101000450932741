<template>
  <sm-card
    no-padding
    :header-title="$t('customer_groups.members.card_title')">
    <template #headerActions>
      <sm-button
        :href="$page.props.routes.showCustomerGroupMember"
        :title="$t('generic.all')" />
    </template>
    <sm-datatable
      loaded
      :data="table">
      <template #columns>
        <Column
          v-for="(col, index) of tableColumns"
          :key="col + '_' + index"
          :field="col"
          :header="$t(`customer_groups.members.columns.${col}`)"
        >
          <template #body="slotProps">
            <template
              v-if="col === 'name'">
              <sm-avatar
                :user="slotProps.data?.customer"
                class="mb-1" />
            </template>
            <template
              v-else-if="col === 'created_at'">
              {{ useDateFormat(slotProps.data?.created_at, 'long') }}
            </template>
            <template v-else-if="col === 'email' || col === 'phone'">
              {{ slotProps.data?.customer[col] }}
            </template>
            <template v-else>
              {{ slotProps.data?.[col] }}
            </template>
          </template>
        </Column>
        <Column field="actions">
          <template #body="slotProps">
            <div class="flex items-center justify-end space-x-1">
              <sm-button
                v-if="slotProps.data?.permissions.canView"
                v-tippy="$t('customer_groups.index.profile')"
                :title="$t('customer_groups.index.profile')"
                :href="slotProps.data?.routes.show" />
            </div>
          </template>
        </Column>
      </template>
    </sm-datatable>
  </sm-card>
</template>

<script setup>
import { useDateFormat } from "@/inertia/composables/global";
import { usePage } from "@inertiajs/vue3";

const page = usePage();

const tableColumns = ref(["name", "email", "phone", "created_at"]);

const table = computed(() => page.props.members);
</script>
