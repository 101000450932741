<template>
  <div>
    <In-Head :title="$t('transactions.show.title')" />

    <sub-header :title="$t('transactions.show.title')">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
      <template #actions>
        <transaction-actions
          v-if="!transaction.trashed"
          :transaction="transaction"
          @triggerShowInvoiceModal="triggerShowInvoiceModal" />
      </template>
    </sub-header>

    <h4>{{ $t('sales.inventory.show.data.title') }}</h4>
    <sm-card>
      <table class="sm-table sm-table-no-heading sm-table-bordered">
        <tbody>
          <tr>
            <td><strong>{{ $t('transactions.index.table.id') }}</strong></td>
            <td>{{ transaction.id }}</td>
          </tr>
          <tr>
            <td><strong>{{ $t('transactions.index.table.paid_at') }}</strong></td>
            <td><sm-boolean-flag :value="transaction.paid_at" /></td>
          </tr>
          <tr>
            <td><strong>{{ $t('transactions.index.table.customer') }}</strong></td>
            <td>
              <sm-avatar
                :user="transaction.payer" />
            </td>
          </tr>
          <tr>
            <td><strong>{{ $t('transactions.index.table.amount') }}</strong></td>
            <td>{{ transaction.amount }}</td>
          </tr>
          <tr>
            <td><strong>{{ $t('transactions.index.table.status') }}</strong></td>
            <td>
              {{ transaction.status }} <span
                v-if="transaction.trashed"
                class="ml-1 font-bold text-bo-red">({{ $t('generic.deleted2') }})</span>
            </td>
          </tr>
          <tr>
            <td><strong>{{ $t('transactions.index.table.payment_type') }}</strong></td>
            <td>{{ transaction.payment_type }}</td>
          </tr>
          <tr>
            <td><strong>{{ $t('transactions.index.table.invoice') }}</strong></td>
            <td>
              <template v-if="transaction.permissions.canInvoice">
                <form
                  v-if="transaction.invoice.transaction_invoice_exists"
                  method="post"
                  :action="transaction.routes.download_invoice">
                  <input
                    type="hidden"
                    name="_token"
                    :value="$page.props.csrfToken">
                  <sm-button
                    id="downloadTransactionButton"
                    type="submit"
                    icon="download"
                    :title="$t('transactions.show.download_invoice', {invoice: transaction.invoice.transaction_invoice_id})"
                  />
                </form>
                <template v-else-if="transaction.billable">
                  <template v-if="transaction.permissions.createInvoice">
                    <sm-form-button
                      v-if="transaction.user_invoice_setting"
                      :route="transaction.routes.create_invoice"
                      name="create_invoice_row"
                      method="post"
                      icon="plus"
                      :title="$t('transactions.show.create_invoice')"
                      confirm
                      :confirm-body="$t('transactions.show.create_invoice_modal.title')"
                      :accept-label="$t('transactions.show.create_invoice_modal.button')"
                      :only="['transaction', 'flash', 'errors']"
                    />
                    <pick-invoice-modal
                      v-else
                      :show-invoice-modal="showInvoiceModal"
                      @triggerShowInvoiceModal="triggerShowInvoiceModal" />
                  </template>
                </template>
                <p v-else>
                  {{ $t('transactions.show.no_invoice') }}
                </p>
              </template>
            </td>
          </tr>
          <tr>
            <td><strong>{{ $t('transactions.index.table.locked_at') }}</strong></td>
            <td>{{ transaction.locked_at ? useDateFormat(transaction.locked_at, 'long') : '' }}</td>
          </tr>
          <tr>
            <td><strong>{{ $t('transactions.index.table.paid_at') }}</strong></td>
            <td>{{ transaction.paid_at ? useDateFormat(transaction.paid_at, 'long') : '' }}</td>
          </tr>
          <tr>
            <td><strong>{{ $t('transactions.index.table.stored_by') }}</strong></td>
            <td>{{ transaction.stored_by }}</td>
          </tr>
          <tr>
            <td><strong>{{ $t('transactions.index.table.note') }}</strong></td>
            <td>
              <div class="flex items-center">
                <span
                  v-if="transaction.note"
                  class="mr-2">{{ transaction.note }}</span>
                <edit-note-modal :note="transaction.note" />
              </div>
            </td>
          </tr>
          <tr>
            <td><strong>{{ $t('transactions.index.table.take_away') }}</strong></td>
            <td><sm-boolean-flag :value="transaction.take_away" /></td>
          </tr>
          <template v-if="transaction.is_refund_transaction && transaction.refund_transaction">
            <tr>
              <td><strong>{{ $t('transactions.index.table.refund_transaction') }}</strong></td>
              <td>
                <Link
                  v-if="transaction.refund_transaction.permissions.canView"
                  :href="transaction.refund_transaction.routes.show">
                  {{ transaction.refund_transaction.id }}
                </Link>
                <span v-else>{{ transaction.refund_transaction.id }}</span>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </sm-card>

    <template v-if="transaction.is_online">
      <transaction-online-payment-details />
    </template>

    <h4>{{ $t('transactions.show.details') }}</h4>

    <sm-card no-padding>
      <sm-table
        :table-data="transactionItemsTableData"
        table-heading-key="transactions.show.table"
        :table-columns="transactionItemsTableColumns" />
    </sm-card>

    <template v-if="transaction.refund_transactions.length">
      <h4>{{ $t('transactions.show.refund_transactions') }}</h4>

      <sm-card no-padding>
        <sm-table
          :table-data="refundTransactionsTableData"
          table-heading-key="transactions.show.refund_transactions_table"
          :table-columns="refundTransactionsTableColumns">
          <template #id="slotProps">
            <Link
              v-if="slotProps.data.permissions.canView"
              :href="slotProps.data.routes.show">
              {{ slotProps.data.id }}
            </Link>
            <span v-else>{{ slotProps.data.id }}</span>
          </template>
        </sm-table>
      </sm-card>
    </template>

    <template v-if="ntakSyncs.length">
      <ntak-sync-table :syncs="ntakSyncs" />
    </template>
  </div>
</template>

<script setup>
import { useDateFormat } from "@/inertia/composables/global";
import { usePage, Link, router } from "@inertiajs/vue3";
import EditNoteModal from "./_partials/EditNoteModal.vue";
import PickInvoiceModal from "./_partials/PickInvoiceModal.vue";
import TransactionActions from "./_partials/TransactionActions.vue";
import TransactionOnlinePaymentDetails from "./_partials/TransactionOnlinePaymentDetails.vue";
import NtakSyncTable from "../../../../components/ntak/NtakSyncTable.vue";

const showInvoiceModal = ref(false);
const triggerShowInvoiceModal = () => {
  showInvoiceModal.value = !showInvoiceModal.value;
};

const page = usePage();
const transaction = computed(() => page.props.transaction);
const ntakSyncs = computed(() => page.props.ntakSyncs ?? []);

const transactionItemsTableColumns = ref(["name", "amount", "price_unit", "total_price"]);
const transactionItemsTableData = ref(page.props.transactionItems ?? []);

const refundTransactionsTableColumns = ref(["id"]);
const refundTransactionsTableData = ref(page.props.transaction.refund_transactions);

onMounted(() => {
  router.reload({
    only: ["ntakSyncs"],
  });
});
</script>
