<template>
  <sm-card
    :header-title="$t('Napi belépések')"
    :header-sub-title="$t('Napi belépések óránkénti bontásban.')"
    class="!mb-0"
    v2
  >
    <template #headerActions>
      <div
        v-if="loaded"
        class="flex items-center space-x-2">
        <div class="relative flex items-center space-x-2">
          <span v-if="today != day">
            <button
              type="button"
              class="text-sm sm-link lh-0"
              @click="filterToday">{{ $t('Ma') }}</button>
            <span class="p-1 text-bo-gray-400"> | </span>
          </span>
          <button
            type="button"
            class="flex items-center justify-center space-x-2 text-sm cursor-pointer"
            @click="toggleDatePicker">
            <span>{{ useDateFormat(day) }}</span>
            <chevron-down-icon
              class="w-4"
              stroke-width="2.5"
            />
          </button>
          <div v-auto-animate>
            <div
              v-if="datePicker"
              ref="datePickerRef"
              class="absolute top-3 right-0 p-4 bg-white border rounded-lg min-w-[200px] z-10">
              <sm-date-picker
                v-model="day"
                class="mb-0"
                @update:modelValue="loadData">
                <template #append>
                  <calendar-icon
                    class="w-4"
                    stroke-width="2.5" />
                </template>
              </sm-date-picker>
            </div>
          </div>
        </div>

        <button
          type="button"
          class="sm-link text-lg leading-[0]"
          @click="loadData">
          <rotate-cw-icon
            class="w-4"
            stroke-width="2.5" />
        </button>
      </div>
      <div class="text-right">
        <p class="text-xs text-bo-gray-400">
          <strong>Összesen: {{ checkinsCount }}</strong>
        </p>
      </div>
    </template>

    <div v-auto-animate>
      <div v-if="loaded">
        <Bar
          :data="chartdata"
          :height="250"
          :options="options" />
      </div>
      <div
        v-else
        class="flex items-center justify-center w-full h-[250px]">
        <vue-feather
          type="rotate-cw"
          class="animate-spin"
          size="18px"
        />
      </div>
    </div>
  </sm-card>
</template>

<script setup>
import { useDateFormat } from "@/inertia/composables/global";
import { usePage } from "@inertiajs/vue3";
import axios from "axios";
import { BarElement, CategoryScale, Chart as ChartJS, LinearScale, Title, Tooltip } from "chart.js";
import { onMounted, ref } from "vue";
import { Bar } from "vue-chartjs";
import { CalendarIcon, ChevronDownIcon, RotateCwIcon } from "vue-feather-icons";
import { onClickOutside } from "@vueuse/core";

ChartJS.register(Title, Tooltip, BarElement, CategoryScale, LinearScale);

const page = usePage();

const loaded = ref(false);
const day = ref(null);
const checkinsCount = ref(null);
const chartdata = ref(null);
const options = ref({
  scales: {
    y: {
      beginAtZero: true,
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  height: 50,
  plugins: {
    legend: {
      display: false,
    }
  }
});

const today = computed(() => useDateFormat(new Date(), "form"));

const loadData = async () => {
  loaded.value = false;
  try {
    const url = makeUrl();
    const response = await axios.get(url);
    const { data, meta } = response.data;

    if (!day.value) {
      day.value = meta.day;
    }

    checkinsCount.value = data.checkins_count;
    setChartData(data);
    loaded.value = true;
  } catch (e) {
    console.error(e);
  }
};

const setChartData = (data) => {
  if (datePicker.value) {
    datePicker.value = false;
  }
  chartdata.value = {
    labels: data.labels,
    datasets: [
      {
        label: "Belépések",
        backgroundColor: "#3490dc",
        data: data.values,
      },
    ]
  };
};

const makeUrl = () => {
  let url = `${page.props.routes.hourlyCheckins}`;

  if (day.value) {
    url += `?day=${useDateFormat(day.value, "form")}`;
  }

  return url;
};

const filterToday = () => {
  day.value = today.value;
  loadData();
};

const datePicker = ref(false);
const datePickerRef = ref();

const toggleDatePicker = () => {
  datePicker.value = !datePicker.value;
};

onClickOutside(datePickerRef, () => {
  if (datePickerRef.value) {
    datePicker.value = !datePicker.value;
  }
});

onMounted(() => {
  loadData();
});
</script>
