<template>
  <sm-card
    :header-title="$t('Napi belépések')"
    :header-sub-title="$t('Napi belépések összesen az adott időszakra.')"
    class="!mb-0"
    v2
  >
    <template #headerActions>
      <div
        v-if="loaded"
        class="flex items-center space-x-2">
        <div class="relative">
          <button
            type="button"
            class="flex items-center justify-center space-x-2 text-sm cursor-pointer"
            @click="toggleYearPicker">
            <span>{{ comparisonYear }}</span>
            <chevron-down-icon
              class="w-4"
              stroke-width="2.5"
            />
          </button>
          <div v-auto-animate>
            <div
              v-if="yearPicker"
              ref="yearPickerRef"
              class="absolute top-0 right-0 p-4 bg-white border rounded-lg min-w-[200px] z-10">
              <sm-select
                v-model="comparisonYear"
                :options="years"
                class="mb-0"
                @update:modelValue="loadData">
                <template #append>
                  <calendar-icon
                    class="w-4"
                    stroke-width="2.5" />
                </template>
              </sm-select>
            </div>
          </div>
        </div>

        <button
          type="button"
          class="sm-link text-lg leading-[0]"
          @click="loadData">
          <rotate-cw-icon
            class="w-4"
            stroke-width="2.5" />
        </button>
      </div>
    </template>

    <div v-auto-animate>
      <div v-if="loaded">
        <Line
          :data="chartdata"
          :height="250"
          :options="options" />
      </div>
      <div
        v-else
        class="flex items-center justify-center w-full h-[250px]">
        <vue-feather
          type="rotate-cw"
          class="animate-spin"
          size="18px"
        />
      </div>
    </div>
  </sm-card>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { Line } from "vue-chartjs";

import { usePage } from "@inertiajs/vue3";
import axios from "axios";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip
} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import { CalendarIcon, ChevronDownIcon, RotateCwIcon } from "vue-feather-icons";
import { onClickOutside } from "@vueuse/core";

ChartJS.register(
  Title, Tooltip, CategoryScale, LinearScale, PointElement, LineElement, zoomPlugin, Legend
);

const page = usePage();

const years = computed(() => {
  let years = [];
  let currentYear = new Date().getFullYear();

  for (let year = 2015; year <= currentYear; year++) {
    years.push(year);
  }

  return years;
});

const loaded = ref(false);
const comparisonYear = ref(new Date().getFullYear() - 1);

const chartdata = ref(null);
const options = ref({
  interaction: {
    mode: "index",
  },
  scales: {
    y: {
      beginAtZero: true,
      title: {
        display: true,
      },
    },
    x: {
      ticks: {
        display: true,
      },
      title: {
        display: true,
      },
      grid: {
        display: false,
      },
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    zoom: {
      pan: {
        enabled: true,
        mode: "x",
      },
      wheel: {
        enabled: true,
        mode: "x",
      },
      pinch: {
        enabled: true,
        mode: "x",
      },
      drag: {
        enabled: true,
      },
    },
  },
});

const loadData = async () => {
  loaded.value = false;
  try {
    const url = makeUrl();
    const response = await axios.get(url);
    const { data, meta } = response.data;

    if (!comparisonYear.value) {
      comparisonYear.value = meta.comparison_year;
    }

    setChartData(data);
    loaded.value = true;
  } catch (e) {
    console.error(e);
  }
};

const setChartData = (data) => {
  if (yearPicker.value) {
    yearPicker.value = false;
  }
  chartdata.value = {
    labels: data.selected_interval.labels,
    datasets: [
      {
        label: new Date().getFullYear(),
        data: data.selected_interval.values,
        backgroundColor: "#3490dc",
        borderColor: "#3490dc",
        fill: false,
        lineTension: 0,
        pointRadius: 0,
        pointHitRadius: 4,
      },
      {
        label: comparisonYear.value,
        data: data.compared_to_interval.values,
        backgroundColor: "#FF0000",
        borderColor: "#FF0000",
        fill: false,
        lineTension: 0,
        pointRadius: 0,
        pointHitRadius: 4,
      },
    ],
  };
};

const makeUrl = () => {
  let url = `${page.props.routes.dailyCheckins}`;

  if (comparisonYear.value) {
    url += `?comparison_year=${comparisonYear.value}`;
  }

  return url;
};

const yearPicker = ref(false);
const yearPickerRef = ref();

const toggleYearPicker = () => {
  yearPicker.value = !yearPicker.value;
};

onClickOutside(yearPickerRef, () => {
  if (yearPickerRef.value) {
    yearPicker.value = !yearPicker.value;
  }
});

onMounted(loadData);
</script>
