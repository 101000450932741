<template>
  <div>
    <In-Head :title="$t('customers.tickets.title', {name: $page.props.customer.name})" />

    <sub-header :title="$t('customers.tickets.title', {name: $page.props.customer.name})">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
      <template #actions>
        <sm-button
          :href="$page.props.routes.customer"
          preset="back"
          icon="arrow-left" />
      </template>
    </sub-header>

    <sm-card no-padding>
      <sm-datatable
        :data="table"
        :sorts="sorts"
        paginator>
        <template #columns>
          <Column
            v-for="(col, index) of tableFields"
            :key="col + '_' + index"
            :field="col"
            :header="col !== 'actions' ? $t(`customers.tickets.table.${col}`) : ''"
            :sortable="sorts.allowed.find(data => data === col)">
            <template #body="slotProps">
              <div :class="{'text-bo-gray-400': !slotProps.data?.usable}">
                <template v-if="col === 'ticket_type'">
                  <template v-if="slotProps.data?.routes?.editTicket">
                    <a
                      :href="slotProps.data?.routes?.editTicket"
                      class="text-bo-blue">
                      {{ slotProps.data?.ticketType }}
                    </a>
                  </template>
                  <span v-else>
                    {{ slotProps.data?.ticketType }}
                  </span>
                </template>
                <template v-else-if="col === 'properties'">
                  <template v-if="slotProps.data?.trashed">
                    <ticket-property-icon :legend="findLegend('trashed')" />
                  </template>
                  <template v-else>
                    <template v-if="slotProps.data?.isExtended">
                      <ticket-property-icon :legend="findLegend('extended')" />
                    </template>
                    <template v-if="slotProps.data?.addedLater">
                      <ticket-property-icon :legend="findLegend('addedLater')" />
                    </template>
                    <template v-if="slotProps.data?.notActiveYet">
                      <ticket-property-icon :legend="findLegend('notActiveYet')" />
                    </template>
                    <template v-if="slotProps.data?.isExpired || !slotProps.data?.usable">
                      <ticket-property-icon :legend="findLegend('expiredOrNotUsable')" />
                    </template>
                    <template v-if="slotProps.data?.usable && !slotProps.data?.isExpired">
                      <ticket-property-icon :legend="findLegend('usableAndNotExpired')" />
                    </template>
                    <template v-if="slotProps.data?.boughtWithInstallments">
                      <ticket-property-icon :legend="findLegend('boughtWithInstallments')" />
                    </template>
                    <template v-if="slotProps.data?.hasVoucher">
                      <ticket-property-icon
                        :legend="findLegend('hasVoucher')"
                        :custom-title="slotProps.data?.voucherCode"
                        class="cursor-pointer"
                        @click="copyVoucherCode(slotProps.data?.voucherCode)" />
                    </template>
                  </template>
                </template>
                <template v-else-if="col === 'max_usage_count'">
                  <div class="inline-flex items-center space-x-2">
                    <vue-feather
                      v-if="!slotProps.data?.isLimitless"
                      v-tippy="$t('tickets.customer_tickets.tooltips.remaining_uses')"
                      type="info"
                      class="w-4 min-w-[1rem]" />
                    <span>
                      {{ slotProps.data?.availableUsages }}
                    </span>
                  </div>
                </template>
                <template v-else-if="col === 'validity'">
                  <template v-if="!slotProps.data?.validFrom && !slotProps.data?.validTo">
                    -
                  </template>
                  <template v-else-if="slotProps.data?.validFrom && slotProps.data?.validTo">
                    {{ $t('customers.tickets.table.validities.between', { from: useDateFormat(slotProps.data?.validFrom), to: useDateFormat(slotProps.data?.validTo) }) }}
                  </template>
                  <template v-else-if="slotProps.data?.validFrom">
                    {{ $t('customers.tickets.table.validities.from', { from: useDateFormat(slotProps.data?.validFrom) }) }}
                  </template>
                  <template v-else-if="slotProps.data?.validTo">
                    {{ $t('customers.tickets.table.validities.to', { to: useDateFormat(slotProps.data?.validTo) }) }}
                  </template>
                </template>
                <template v-else-if="col === 'activation_date'">
                  {{ slotProps.data?.createdAt ? useDateFormat(slotProps.data?.createdAt) : '' }}
                </template>
                <template v-else-if="col === 'stored_by'">
                  {{ slotProps.data?.createdBy }}
                </template>
                <template v-else-if="col === 'checkins_count'">
                  {{ slotProps.data?.checkinsCount }}
                </template>
                <template v-else-if="col === 'actions'">
                  <div
                    v-if="slotProps.data"
                    :key="slotProps.data.id"
                    class="text-right"
                  >
                    <template v-if="slotProps.data.group && !slotProps.data.isCustomersTickets">
                      <a
                        :href="slotProps.data.routes.viewCustomerGroup"
                        class="text-bo-blue"
                      >
                        {{ slotProps.data.group }}
                      </a>
                    </template>
                    <template v-else-if="!slotProps.data.trashed">
                      <ticket-actions :slot-props="slotProps" />
                    </template>
                  </div>
                </template>
                <template v-else>
                  {{ slotProps.data?.[col] }}
                </template>
              </div>
            </template>
          </Column>
        </template>
      </sm-datatable>
      <div class="px-4 pt-4 pb-4 border-t border-bo-gray-100">
        <div class="space-y-2 sm-row lg:space-y-0 lg:justify-between">
          <div
            v-for="(legend, index) of legends"
            :key="index"
            class="inline-flex items-center w-full sm-col lg:w-auto">
            <ticket-property-icon
              :legend="findLegend(legend.id)"
              class="mr-2" />
            {{ legend.title }}
          </div>
        </div>
      </div>
    </sm-card>

    <div id="action-modals" />
  </div>
</template>

<script setup>
import { useDateFormat } from "@/inertia/composables/global";
import TicketActions from "@/inertia/pages/customers/show/tickets/_partials/TicketActions.vue";
import TicketPropertyIcon from "@/inertia/pages/customers/show/tickets/_partials/TicketPropertyIcon.vue";
import { useToast } from "vue-toastification";

const toast = useToast();

defineProps({
  table: { type: Object, required: false, default: () => {} },
  sorts: { type: Object, required: false, default: () => {} },
});

const tableFields = ref(["ticket_type", "properties", "max_usage_count", "validity", "activation_date", "stored_by", "checkins_count", "actions"]);

// legends helper
const legends = ref([
  {
    id: "usableAndNotExpired",
    icon: "check",
    title: window.trans("customers.tickets.table.legends.usable_and_not_expired"),
    classes: "text-bo-green"
  },
  {
    id: "expiredOrNotUsable",
    icon: "clock",
    title: window.trans("customers.tickets.table.legends.expired_or_not_usable"),
    classes: "text-bo-red"
  },
  {
    id: "trashed",
    icon: "trash-2",
    title: window.trans("customers.tickets.table.legends.trashed"),
    classes: "text-bo-red"
  },
  {
    id: "notActiveYet",
    icon: "skip-forward",
    title: window.trans("customers.tickets.table.legends.not_active_yet"),
    classes: "text-bo-blue"
  },
  {
    id: "addedLater",
    icon: "skip-back",
    title: window.trans("customers.tickets.table.legends.added_later"),
    classes: ""
  },
  {
    id: "extended",
    icon: "plus-circle",
    title: window.trans("customers.tickets.table.legends.extended"),
    classes: ""
  },
  {
    id: "boughtWithInstallments",
    icon: "layers",
    title: window.trans("customers.tickets.table.legends.bought_with_installments"),
    classes: ""
  },
  {
    id: "hasVoucher",
    icon: "gift",
    title: window.trans("customers.tickets.table.legends.has_voucher"),
    classes: ""
  },
]);

const findLegend = (id) => {
  return legends.value.find(data => data.id === id);
};

const copyVoucherCode = async (code) => {
  if (! window.isSecureContext) {
    toast.error("A másolás csak HTTPS módban működik.");
    return;
  }

  try {
    await navigator.clipboard.writeText(code);
    toast.success("Link a vágólapra másolva", {
      position: "bottom-center"
    });
  } catch ($e) {
    console.log($e);
  }
};
</script>

