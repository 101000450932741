<template>
  <div>
    <button
      id="support-form"
      type="button"
      class="fixed flex items-center p-4 text-base rounded-full bottom-4 lg:bottom-10 right-4 lg:right-10 sm-btn sm-btn-filter h-[54px] z-10"
      :class="{'group': !open}"
      @click="toggleOpen">
      <div
        class="inline-block relative !w-5 !h-5 transition-all"
      >
        <x-icon
          stroke-width="2.5"
          class="inline-block !w-5 !h-5 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transition-opacity"
          :class="open ? 'opacity-100' : 'opacity-0'" />
        <message-square-icon
          stroke-width="2.5"
          class="inline-block !w-5 !h-5 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transition-opacity"
          :class="open ? 'opacity-0' : 'opacity-100'" />
      </div>
      <span
        class="w-[94px] transition-all overflow-hidden max-w-0 group-hover:max-w-[100px] group-hover:ml-2"
      >Segíthetünk?</span>
    </button>
    <div
      class="fixed sm-card mb-16 bottom-4 lg:bottom-10 right-2 lg:right-10 lg:w-[350px] w-[calc(100vw-1rem)] transition-all overflow-hidden z-10"
      :class="open ? 'opacity-100 translate-y-0 pointer-events-auto delay-100' : 'opacity-0 translate-y-4 pointer-events-none'">
      <header class="p-4 bg-bo-purple">
        <h4 class="mb-0 text-white">
          Új üzenet küldése
        </h4>
        <p class="text-xs text-white text-md">
          Igyekszünk a lehető legrövidebb idő alatt válaszolni!
        </p>
      </header>
      <div class="sm-card-body bg-bo-gray-50 max-h-[300px] lg:max-h-[calc(100vh-60px-48px-76px-71px-70px)] overflow-y-auto">
        <form>
          <div class="mb-2">
            <input
              id="name"
              v-model="form.name"
              name="name"
              type="text"
              placeholder="Teljes név"
              class="sm-input-text">
            <form-error :error="errors?.name" />
          </div>
          <div class="mb-2">
            <input
              id="mail"
              v-model="form.email"
              name="email"
              type="email"
              placeholder="Saját e-mail cím"
              :class="{'has-error': errors?.email}"
              class="sm-input-text">
            <form-error :error="errors?.email" />
          </div>
          <div class="mb-2">
            <input
              id="phone"
              v-model="form.phone"
              name="phone"
              type="text"
              placeholder="Telefonszám"
              :class="{'has-error': errors?.phone}"
              class="sm-input-text">
            <form-error :error="errors?.phone" />
          </div>
          <div class="mb-2">
            <select
              id="id"
              v-model="form.environment"
              name="environment"
              :class="{'has-error': errors?.environment}"
              class="bg-white sm-input-text">
              <option
                selected
                disabled
                :value="null">
                Környezet
              </option>
              <option
                v-for="environment in environments"
                :key="environment.id"
                :value="environment.id">
                {{ environment.name }}
              </option>
            </select>
            <form-error :error="errors?.environment" />
          </div>
          <div class="mb-2">
            <select
              id="id"
              v-model="form.ticketType"
              name="ticketType"
              :class="{'has-error': errors?.ticketType}"
              class="bg-white sm-input-text">
              <option
                selected
                disabled
                :value="null">
                Jelentés típusa
              </option>
              <option
                v-for="ticketType in ticketTypes"
                :key="ticketType.id"
                :value="ticketType.id">
                {{ ticketType.name }}
              </option>
            </select>
            <form-error :error="errors?.ticketType" />
          </div>
          <div class="mb-2">
            <select
              id="id"
              v-model="form.module"
              name="module"
              :class="{'has-error': errors?.module}"
              class="bg-white sm-input-text">
              <option
                selected
                disabled
                :value="null">
                Hol a hiba?
              </option>
              <option
                v-for="item in modules"
                :key="item.id"
                :value="item.id">
                {{ item.name }}
              </option>
            </select>
            <form-error :error="errors?.module" />
          </div>
          <div class="mb-2">
            <input
              id="id"
              v-model="form.subject"
              name="subject"
              type="subject"
              placeholder="Tárgy"
              :class="{'has-error': errors?.subject}"
              class="sm-input-text">
            <form-error :error="errors?.subject" />
          </div>
          <div class="mb-2">
            <textarea
              id="message"
              v-model="form.message"
              name="message"
              rows="5"
              placeholder="Szia! Miben segíthetünk?"
              :class="{'has-error': errors?.message}"
              class="sm-input-text" />
            <form-error :error="errors?.message" />
          </div>
          <div class="mb-2">
            <div class="p-0 sm-input-text">
              <label
                class="block sm-input-file-label"
                for="file">
                {{ fileName || "Csatolmány hozzáadása" }}
              </label>
              <input
                id="file"
                type="file"
                name="file"
                class="hidden"
                placeholder="placeholder"
                @input="updateInput($event)">
            </div>
            <form-error :error="errors?.file" />
          </div>
        </form>
      </div>
      <div class="p-4 border-t border-bo-gray-100">
        <button
          type="button"
          class="sm-btn sm-btn-full sm-btn-filter"
          :disabled="isLoading"
          @click="onSubmit">
          <transition name="loader">
            <vue-feather
              v-if="isLoading"
              type="rotate-cw"
              class="inline mr-1 animate-spin" />
          </transition>
          Üzenet küldése
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import axios from "axios";
import { MessageSquareIcon, XIcon } from "vue-feather-icons";
import { useToast } from "vue-toastification";
import FormError from "./inputs/FormError.vue";

const props = defineProps({
  name: { type: String, required: true },
  email: { type: String, required: true },
  phone: { type: String, required: false, default: "" },
  route: { type: String, required: true },
  environments: { type: Array, required: true },
  ticketTypes: { type: Array, required: true },
  modules: { type: Array, required: true },
});

const toast = useToast();

const form = ref({
  name: props.name,
  email: props.email,
  phone: props.phone,
  subject: "",
  message: "",
  file: null,
  environment: null,
  ticketType: null,
  module: null,
});

const errors = ref({});
const isLoading = ref(false);
const open = ref(false);
const fileName = ref("");

const toggleOpen = () => {
  open.value = !open.value;
};

function updateInput(e) {
  fileName.value = e.target.files[0].name;
  form.value.file = e.target.files[0];
}

const onSubmit = async () => {
  isLoading.value = true;

  await axios.post(props.route, {
    name: form.value.name,
    email: form.value.email,
    phone: form.value.phone,
    subject: form.value.subject,
    message: form.value.message,
    file: form.value.file,
    environment: form.value.environment,
    ticketType: form.value.ticketType,
    module: form.value.module,
  }, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  })
    .then(() => {
      isLoading.value = false;
      errors.value = {};
      toggleOpen();
      toast.success("Köszönjük a megkeresést! Hamarosan felvesszük veled a kapcsolatot!");
      form.value = {
        ...form.value,
        message: "",
        subject: "",
        environment: null,
        ticketType: null,
        module: null,
      };
    })
    .catch((e) => {
      isLoading.value = false;
      errors.value = e.response.data.errors;
    });
};
</script>

<style scoped>
.loader-enter-active,
.loader-leave-active {
  @apply transition-all origin-center;
}

.loader-enter-from,
.loader-leave-to {
  @apply opacity-0 scale-0 w-0;
}

.loader-enter-to,
.loader-leave-from {
  @apply opacity-100 scale-100 w-4;
}
</style>
