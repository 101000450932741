// GLobal imports (js)
import { createApp } from "vue";
import Cleave from "cleave.js";
import copy from "copy-to-clipboard";
import jQuery from "jquery";
import "spectrum-colorpicker/spectrum.css";
import "spectrum-colorpicker/spectrum.js";
// import draggable from "vuedraggable";
import { createStore } from "vuex";

// Local imports
import "./Translation";
import { dialogStore } from "./plugins/ConfirmDialog";
import appointmentModule from "../backoffice/modules/appointments/store";
import cafeteriaModule from "../backoffice/modules/cafeteria/store";
import GlobalVue from "./GlobalVue";

const $ = jQuery;

const Core = {
  createVueApp(options) {
    const app = createApp(options);

    // Initialize directives
    GlobalVue.registerDirectives(app);

    // Initialize components
    GlobalVue.registerComponents(app);

    // Initialize composables
    GlobalVue.registerComposables(app);

    // Initialize Vuex store
    const store = createStore({
      modules: {
        appointments: appointmentModule,
        cafeteria: cafeteriaModule,
        dialog: dialogStore,
      },
    });

    // Global Vue properties
    app.config.globalProperties.$store = store;
    app.config.globalProperties.$t = window.trans;
    app.config.compilerOptions.whitespace = "preserve";

    app.use(store);

    return app;
  },
  initVue() {
    const elements = document.querySelectorAll(".vue-root");

    elements.forEach(element => {
      if (element.parentElement.closest(".vue-root")) {
        return;
      }

      const app = Core.createVueApp();

      app.mount(element);
    });
  },
  initCleave() {
    document.querySelectorAll("[data-cleave]").forEach(el => {
      new Cleave(el, JSON.parse(el.dataset.cleave));
    });
  },
  initSpectrum() {
    document.querySelectorAll("input.colorpicker").forEach(el => {
      $(el).spectrum({
        preferredFormat: el.dataset.format ? el.dataset.format : "rgb",
        allowEmpty: false,
        showAlpha: true,
        showInitial: true,
        showInput: true,
        chooseText: "OK",
        cancelText: "Mégse",
      });
    });
  },
  listen(type, selector, callback) {
    document.addEventListener(type, event => {
      const element = event.target.closest(selector);

      if (element) {
        callback(event, element);
      }
    });
  },
  async guardFormDoubleSubmissions() {
    const forms = document.querySelectorAll("form");

    forms.forEach(form => {
      form.addEventListener("submit", e => {
        const submitButton = Core.findSubmitButtonOfForm(e.target);

        if (submitButton) {
          submitButton.disabled = true;
        }
      });

      form.addEventListener("reset", e => {
        const submitButton = Core.findSubmitButtonOfForm(e.target);

        if (submitButton) {
          submitButton.disabled = false;
        }
      });
    });
  },
  copyToClipboard(value) {
    copy(value);
  },
  findSubmitButtonOfForm(form) {
    let submitButton = form.querySelector("button[type=submit]");

    if (!submitButton && form.id) {
      // If no submit button was found, try to find one which has a form attribute set to this form's id
      submitButton = document.querySelector(`button[type=submit][form=${form.id}]`);
    }

    return submitButton;
  }
};

export default Core;
