<template>
  <sm-input-wrapper>
    <template
      v-if="$slots.afterLabel"
      #afterLabel>
      <slot name="afterLabel" />
    </template>
    <template
      v-if="$slots.prepend"
      #prepend>
      <slot name="prepend" />
    </template>
    <template
      v-if="$slots.afterInput"
      #afterInput>
      <slot name="afterInput" />
    </template>
    <template
      v-if="$slots.append"
      #append>
      <slot name="append" />
    </template>
    <VueDatePicker
      :model-type="isTimePicker ? 'HH:mm' : 'yyyy-MM-dd'"
      auto-apply
      :enable-time-picker="isTimePicker"
      :model-value="modelValue"
      :format="date => useDateFormat(date, isTimePicker ? 'time' : '')"
      :disabled="disabled"
      :required="required"
      :name="name"
      :clearable="clearable"
      :min-date="minDate"
      :max-date="maxDate"
      is-24
      :time-picker="isTimePicker"
      locale="hu-HU"
      :position="position"
      year-first
      arrow-navigation
      :config="isTimePicker ? { modeHeight: 110 } : null"
      :month-change-on-arrows="false"
      @update:model-value="updateInput"
    >
      <template #dp-input="{ value, onInput, onEnter: onInputEnter }">
        <input
          class="mb-0 sm-input-text"
          type="text"
          :placeholder="placeholder"
          :value="value"
          @onChange="onInput"
          @onEnter="onInputEnter">
      </template>
    </VueDatePicker>
  </sm-input-wrapper>
</template>

<script setup>
import SmInputWrapper from "@/inertia/components/forms/_layouts/SmInputWrapper.vue";
import { useDateFormat } from "@/inertia/composables/global";

const props = defineProps({
  name: { type: String, required: false, default: "" },
  id: { type: String, required: false, default: "" },
  inputId: { type: String, required: false, default: "" },
  modelValue: { type: [Date, String], required: false, default: "" },
  label: { type: String, required: false, default: "" },
  placeholder: { type: String, required: false, default: `pl: ${useDateFormat(new Date())}` },
  tooltip: { type: String, required: false, default: "" },
  help: { type: String, required: false, default: "" },
  error: { type: [Boolean, String], required: false, default: false },
  inline: { type: Boolean, required: false, default: false },
  half: { type: Boolean, required: false, default: false },
  disabled: { type: Boolean, required: false, default: false },
  required: { type: Boolean, required: false, default: false },
  inlineInputWidth: { type: String, required: false, default: "" },
  inputRef: { type: String, required: false, default: "" },
  focusToday: { type: Boolean, required: false, default: false },
  clearable: { type: Boolean, required: false, default: false },
  minDate: { type: [Date, String], required: false, default: null },
  maxDate: { type: [Date, String], required: false, default: null },
  position: { type: String, required: false, default: "center" },
  mode: { type: String, required: false, default: "date" },
});

const isTimePicker = props.mode === "time";

provide("props", props);

const emit = defineEmits(["update:modelValue", "change", "onEnter"]);

function updateInput(e) {
  emit("update:modelValue", e);
  emit("change", e);
}
</script>
