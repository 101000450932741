<template>
  <div>
    <In-Head :title="$t('customer_groups.members.title', {name: $page.props.customerGroup.name})" />

    <sub-header :title="$t('customer_groups.members.title', {name: $page.props.customerGroup.name})">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
    </sub-header>

    <div class="sm-row">
      <div class="w-full sm-col lg:w-8/12">
        <sm-card no-padding>
          <sm-datatable
            loaded
            :sorts="sorts"
            :data="table">
            <template #columns>
              <Column
                v-for="(col, index) of tableFields"
                :key="col + '_' + index"
                :field="col"
                :header="$t(`customer_groups.members.columns.${col}`)"
                :sortable="sorts.allowed.find(data => data === col)">
                <template #body="slotProps">
                  <template
                    v-if="col === 'name'">
                    <sm-avatar
                      :user="slotProps.data?.customer"
                      class="mb-1" />
                    <p>{{ slotProps.data?.customer.email }}</p>
                    <p>{{ slotProps.data?.customer.phone }}</p>
                  </template>
                  <template
                    v-else-if="col === 'created_at'">
                    {{ useDateFormat(slotProps.data?.created_at, 'long') }}
                  </template>
                  <template v-else>
                    {{ slotProps.data?.[col] }}
                  </template>
                </template>
              </Column>
              <Column field="actions">
                <template #body="slotProps">
                  <div class="flex items-center justify-end space-x-1">
                    <sm-button
                      v-if="slotProps.data?.permissions.canView"
                      v-tippy="$t('customer_groups.index.profile')"
                      :title="$t('customer_groups.index.profile')"
                      :href="slotProps.data?.routes.show" />
                    <sm-delete-button
                      v-if="slotProps.data?.permissions.canDelete"
                      :route="slotProps.data?.routes.destroy"
                      :name="`${slotProps.data?.id}_delete`"
                    />
                  </div>
                </template>
              </Column>
            </template>
          </sm-datatable>
        </sm-card>
      </div>
      <div class="w-full sm-col lg:w-4/12">
        <sm-card :header-title="$t('customer_groups.members.form.title')">
          <user-picker
            v-model="newUser"
            name="user" />
          <div v-auto-animate>
            <div
              v-if="$page.props.errors.user"
              class="sm-feedback error">
              {{ $page.props.errors.user }}
            </div>
          </div>
          <template #footer>
            <sm-button
              :title="$t('customer_groups.members.form.submit')"
              color="primary"
              :loading="form.processing"
              @click="onSubmit" />
          </template>
        </sm-card>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useDateFormat } from "@/inertia/composables/global";
import { useForm, usePage } from "@inertiajs/vue3";

defineProps({
  table: { type: Object, required: false, default: () => {} },
  sorts: { type: Object, required: false, default: () => { } },
});

const newUser = ref();

const form = useForm({});
const page = usePage();

provide("form", form);

const onSubmit = () => {
  form
    .transform(() => ({
      user: newUser.value.value,
    }))
    .post(page.props.routes.addUser, {
      preserveState: true,
      only: ["table", "errors", "flash"],
      onSuccess: () => {
        form.reset();
      }
    });
};

const tableFields = ["name", "created_at"];
</script>
