<template>
  <div :class="{'sm-table-responsive': responsive}">
    <table
      class="sm-table"
      :class="tableClasses">
      <thead>
        <tr>
          <th
            v-for="(column, colIndex) in tableColumns"
            :key="colIndex"
            :class="headerClasses ? headerClasses[column.id || column] : null"
          >
            {{ tableHeadingKey ? $t(`${tableHeadingKey}.${column.id || column}`) : (column.title || column) }}
          </th>
          <th v-if="$slots.actions" />
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(data, rowIndex) in tableData"
          :key="rowIndex"
          :class="generateRowClasses(data)">
          <td
            v-if="$slots.index"
          >
            <slot
              name="index"
              :data="{...data, rowIndex}" />
          </td>
          <template
            v-for="(value, colIndex) in Object.values(data)"
            :key="colIndex"
          >
            <td
              v-if="tableColumns.map(data => data.id || data).includes(Object.keys(data)[colIndex])"
              :class="columnClasses ? columnClasses[Object.keys(data)[colIndex]] : null">
              <slot
                v-if="$slots[Object.keys(data)[colIndex]]"
                :name="Object.keys(data)[colIndex]"
                :data="data" />
              <template v-else>
                {{ value }}
              </template>
            </td>
          </template>
          <td
            v-if="$slots.actions"
          >
            <slot
              name="actions"
              :data="data" />
          </td>
        </tr>
        <tr v-if="tableData.length === 0">
          <td :colspan="tableColumns.length">
            <sm-alert
              class="z-10"
              color="warning"
              no-margin>
              {{ $t('generic.empty_table') }}
            </sm-alert>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
const props = defineProps({
  tableData: { type: Array, required: true, default: () => [] },
  tableColumns: { type: Array, required: true, default: () => [] },
  size: { type: String, required: false, default: "" }, // sm
  color: { type: String, required: false, default: "" }, // primary, info, warning, success
  bordered: { type: Boolean, required: false, default: false },
  noHeading: { type: Boolean, required: false, default: false }, //no-heading
  responsive: { type: Boolean, required: false, default: false },
  hover: { type: Boolean, required: false, default: false },
  columnClasses: { type: Object, required: false, default: null },
  headerClasses: { type: Object, required: false, default: null },
  tableHeadingKey: { type: String, required: false, default: "" },
  rowClasses: { type: Object, required: false, default: null },
});

const generateRowClasses = (data) => {
  let classArray = [];
  if (props.rowClasses) {
    for (const [key, value] of Object.entries(props.rowClasses)) {
      classArray.push({
        [key]: data?.[value]
      });
    }
  }
  return classArray;
};

const tableClasses = computed(() => {
  const size = props.size === "sm" ? "sm-table-sm" : null;
  const color = props.color ? `sm-table-${props.color}` : null;
  const border = props.bordered ? "sm-table-bordered" : null;
  const noHeading = props.noHeading ? "sm-table-no-heading" : null;
  const hover = props.hover ? "sm-table-hover" : null;
  return `${border ? border : ""} ${color ? color : ""} ${noHeading ? noHeading : ""} ${size ? size : ""} ${hover ? hover : ""}`;
});
</script>
