<template>
  <sm-card :header-title="$t('customers.discounts_page.unique_discounts.title')">
    <div class="items-start sm-row">
      <div class="w-full sm-col lg:w-2/12">
        <sm-select
          v-model="category"
          name="category"
          required
          :label="$t('customers.discounts_page.unique_discounts.fields.category.label')"
          :placeholder="$t('customers.discounts_page.unique_discounts.fields.category.placeholder')"
          :options="discountCategories"
          @input="setSubCategorySearchEndpoint"
        />
      </div>
      <div
        v-if="category && searchEndpoint"
        class="w-full sm-col lg:w-2/12">
        <sm-async-select
          ref="subCategory"
          v-model="search"
          name="sub_category"
          required
          :route="searchEndpoint"
          :label="$t('customers.discounts_page.unique_discounts.fields.sub_category.label')"
          @update:modelValue="setSearchResult"
        />
      </div>
      <div class="w-full sm-col lg:w-2/12">
        <sm-input
          v-model="percentage"
          name="percentage"
          required
          :label="$t('customers.discounts_page.unique_discounts.fields.percentage.label')"
          min="0"
          type="number"
          step="0.0001"
        >
          <template #append>
            <span>%</span>
          </template>
        </sm-input>
      </div>
      <div class="w-full sm-col lg:w-2/12">
        <sm-date-picker-date
          v-model="endDate"
          name="endDate"
          :min-date="new Date()"
          :label="$t('customers.discounts_page.unique_discounts.fields.endDate.label')"
          :help="$t('customers.discounts_page.unique_discounts.fields.endDate.help')"
          :placeholder="`pl: ${useDateFormat(new Date())}`"
        />
      </div>
      <div
        v-if="isStockCategory(category)"
        class="w-full sm-col lg:w-auto">
        <sm-checkbox
          v-model="employeePricing"
          name="employeePricing"
          :label="$t('customers.discounts_page.unique_discounts.fields.employeePricing.label')"
        />
      </div>
      <div class="sm-col lg:pt-label">
        <sm-button
          preset="add"
          color="secondary"
          class="sm-btn-input"
          :disabled="!category || !search || !percentage"
          @click="addDiscount"
        />
      </div>
    </div>

    <table
      class="sm-table">
      <thead>
        <tr>
          <th>{{ $t('customers.discounts_page.unique_discounts.table.category') }}</th>
          <th>{{ $t('customers.discounts_page.unique_discounts.table.name') }}</th>
          <th>{{ $t('customers.discounts_page.unique_discounts.table.employee_pricing') }}</th>
          <th>{{ $t('customers.discounts_page.unique_discounts.table.percentage') }}</th>
          <th>{{ $t('customers.discounts_page.unique_discounts.table.end_date') }}</th>
          <th />
        </tr>
      </thead>
      <tbody
        v-if="discounts.length !== 0">
        <tr
          v-for="discount in discounts"
          :key="discount.id">
          <td>{{ discountCategories.find(data => data.id === discount.category).label }}</td>
          <td>{{ discount.name }}</td>
          <td>
            <sm-checkbox
              v-if="isStockCategory(discount.category)"
              v-model="discount.employee_pricing"
              :name="`discount_${index}`"
              text=""
              class="mb-0"
              sm-switch
            />
            <span v-else>-</span>
          </td>
          <td>
            <sm-input
              v-model="discount.percentage"
              name="percentage"
              required
              min="0"
              type="number"
              step="0.0001"
              class="mb-0"
            >
              <template #append>
                <span>%</span>
              </template>
            </sm-input>
          </td>
          <td>
            <sm-date-picker-date
              v-model="discount.end_date"
              :min-date="new Date()"
              name="endDate"
              class="mb-0"
              :placeholder="`pl: ${useDateFormat(new Date())}`"
            />
          </td>
          <td class="text-right">
            <sm-delete-button
              :name="`deleteDiscout_${discount.id}`"
              @onDelete="deleteDiscount(discount)"
            />
          </td>
        </tr>
      </tbody>
      <empty-table
        v-else
        colspan="5" />
    </table>
    <sm-alert-dialog
      name="discountAdded"
      :visible="isDiscountAddedVisible"
      :message="$t('customers.discounts_page.unique_discounts.errors.has_discount')"
      @onAccept="isDiscountAddedVisible = !isDiscountAddedVisible" />
    <sm-alert-dialog
      name="discountError"
      :visible="isDiscountErrorVisible"
      :message="$t('customers.discounts_page.unique_discounts.errors.percentage')"
      @onAccept="isDiscountErrorVisible = !isDiscountErrorVisible" />
  </sm-card>
</template>

<script>
import { useDateFormat, useFormatSelect } from "@/inertia/composables/global";
import { useForm } from "@inertiajs/vue3";
import { parse } from "date-fns";
import uniqueId from "lodash.uniqueid";

export default {
  provide() {
    return {
      form: this.form
    };
  },
  props: {
    oldDiscounts: { type: Array, default: () => [] },
  },
  emits: ["updateForm"],
  data() {
    const discounts = (this.$page.props.discountItems ?? [])
      .map(discount => {
        return {
          ...discount,
          end_date: parse(discount.end_date, "yyyy-MM-dd", new Date())
        };
      });
    return {
      discounts,
      newDiscount: [],
      searchEndpoint: "",
      search: null,

      // Data for new discounts
      searchResult: null,
      percentage: "",
      category: "",
      employeePricing: false,
      endDate: null,
      uid: uniqueId(),
      isDiscountAddedVisible: false,
      isDiscountErrorVisible: false,
    };
  },
  computed: {
    form() {
      return useForm({
        id: 0
      });
    },
    discountCategories() {
      return useFormatSelect(this.$page.props.discountCategories);
    },
    endpoints() {
      return useFormatSelect(this.$page.props.endpoints);
    },
  },
  watch: {
    discounts: {
      handler(e) {
        this.$emit("updateForm", "discounts", e);
      },
      deep: true
    }
  },
  mounted() {
    this.$emit("updateForm", "discounts", this.discounts);
  },
  methods: {
    useDateFormat(date, type) {
      return useDateFormat(date, type);
    },
    addDiscount() {
      console.log(this.searchResult);
      if (!this.searchResult) {
        return;
      }

      this.newDiscount = {
        category: this.category.id,
        id: this.searchResult.id,
        name: this.searchResult.label,
        percentage: parseInt(this.percentage).toFixed(4),
        employee_pricing: this.employeePricing,
        end_date: this.endDate,
      };

      let element = null;
      if (this.discounts.length !== 0) {
        element = this.discounts.find(element => element.name === this.newDiscount.name);
      }

      if (this.category != null && this.category !== ""
        && this.newDiscount.name != null && this.newDiscount.name !== ""
        && this.searchResult != null && this.searchResult !== "" && this.searchResult
        && this.percentage != null && this.percentage >= 0 && this.percentage <= 100 && !element) {
        this.discounts.push(this.newDiscount);
        this.resetForm();
      } else {
        if (element) {
          this.isDiscountAddedVisible = true;
          return;
        } else {
          this.isDiscountErrorVisible = true;
          return;
        }
      }

      this.resetSubCategory();
    },
    deleteDiscount(deletedDiscount) {
      this.discounts = this.discounts.filter(function (discount) {
        return discount !== deletedDiscount;
      });
    },
    resetForm() {
      this.percentage = "";
      this.searchResult = null;
      this.employeePricing = false;
      this.endDate = null;
      this.category = [];
      this.search = [];
      this.searchEndpoint = "";
    },
    setSearchResult(result) {
      console.log("setSearchResult");
      console.log(result);
      this.searchResult = result;
    },
    resetSubCategory() {
      this.search = [];
    },
    setSubCategorySearchEndpoint() {
      this.searchEndpoint = this.endpoints.find(data => data.id === this.category.id)?.label;

      this.resetSubCategory();
    },
    isStockCategory(category) {
      const stockCategories = [
        "App\\Modules\\Cafeteria\\Models\\ClubStockItem",
        "App\\Modules\\Cafeteria\\Models\\ProductType",
        "App\\Modules\\Cafeteria\\Models\\ProductTypeGroup",
      ];
      return stockCategories.includes(category.id);
    }
  },
};
</script>
