<template>
  <settings-layout>
    <in-head :title="clubName + $t('opening_hours.settings.title')" />
    <sm-card
      :form="form"
      @onSubmit="onSubmit">
      <template #header>
        <div class="flex items-center justify-between">
          <p>
            <strong>{{ clubName }}</strong>
            {{ $t('opening_hours.settings.title') }}
          </p>
          <sm-toggle-button
            title="Helyszínek"
            align="right"
            :first-option="defaultLocation"
            :options="locations">
            <template #firstOption>
              <li>
                <Link
                  href="/manage/settings/opening-hours"
                  class="sm-dropdown-item"
                  :class="{ 'active': $page.url === '/manage/settings/opening-hours' }">
                  Klub
                </Link>
              </li>
            </template>
          </sm-toggle-button>
        </div>
      </template>

      <div
        class="opening-hours-list"
        :class="{'pointer-events-none': cannotUpdatePage}">
        <div
          v-for="(day, index) in daysOfWeek"
          :key="index">
          <div
            class="items-center sm-row"
            :class="{'border-b mb-6': index < 6}">
            <p class="w-1/2 sm-col lg:w-1/12 sm-form-group">
              <strong class="whitespace-nowrap">{{ day }}</strong>
            </p>

            <div class="w-1/2 sm-col lg:w-3/12">
              <div class="flex">
                <sm-checkbox
                  :id="`is_open_${index}`"
                  v-model="form[index].is_open"
                  :name="`is_open_${index}`"
                  text="Nyitva"
                  inline
                  class="-mr-2"
                />
              </div>
            </div>

            <div class="sm-col w-[47.5%] lg:w-3/12">
              <sm-date-picker-2
                :id="`from${index}`"
                v-model="form[index].from"
                mode="time"
                :disabled="!form[index].is_open">
                <template #append>
                  <vue-feather
                    type="clock"
                    class="w-4 h-4" />
                </template>
              </sm-date-picker-2>
            </div>

            <div class="sm-col w-[47.5%] lg:w-3/12">
              <sm-date-picker-2
                :id="`to${index}`"
                v-model="form[index].to"
                mode="time"
                :disabled="!form[index].is_open">
                <template #append>
                  <vue-feather
                    type="clock"
                    class="w-4 h-4" />
                </template>
              </sm-date-picker-2>
            </div>
            <div
              v-if="form?.errors"
              class="pb-2">
              <div v-auto-animate>
                <div
                  v-if="form?.errors[`to[${index}]`]"
                  class="sm-feedback error">
                  {{ form?.errors[`to[${index}]`] }}
                </div>
              </div>
              <div v-auto-animate>
                <div
                  v-if="form?.errors[`from[${index}]`]"
                  class="sm-feedback error">
                  {{ form?.errors[`from[${index}]`] }}
                </div>
              </div>
              <div v-auto-animate>
                <div
                  v-if="form?.errors[`is_open[${index}]`]"
                  class="sm-feedback error">
                  {{ form?.errors[`is_open[${index}]`] }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <sm-button
          preset="save"
          :loading="form.processing"
          @click="onSubmit" />
      </template>
    </sm-card>
  </settings-layout>
</template>

<script setup>
import { Link, useForm, usePage } from "@inertiajs/vue3";
import _ from "lodash";
import { provide } from "vue";

// default variables
const page = usePage();

const props = defineProps({
  locations: { type: Array, required: true, default: null },
  daysOfWeek: { type: Object, required: true, default: null },
  currentLocation: { type: Object, required: false, default: null },
  currentOpeningHours: { type: Object, required: true, default: null },
  cannotUpdatePage: { type: Boolean, required: true, default: null },
});

const daysOfWeek = Object.values(props.daysOfWeek);

const defaultLocation = ref({
  id: 0,
  label: "asdasd"
});

const form = useForm(Object.values(props.currentOpeningHours));

const formattedArray = (array) => {
  const newArray = {
    from: [],
    to: [],
    is_open: [],
  };
  for (const elem of array) {
    newArray.from.push(elem.from.length > 5 ? elem.from.slice(0, -3) : elem.from);
    newArray.to.push(elem.to.length > 5 ? elem.to.slice(0, -3) : elem.to);
    newArray.is_open.push(elem.is_open);
  }
  return newArray;
};

const clubName = ref(page.url.split("/")[4] ? _.toArray(props.locations).find(data => data.id === parseInt(page.url.split("/")[4])).name : "Klub");

const onSubmit = () => {
  form
    .transform((data) => {
      return formattedArray(_.toArray(data));
    })
    .post(page.url);
};

provide("form", form);

</script>
