<template>
  <sm-card
    header-title="Forgalmad"
    header-sub-title="Az aktuális műszakod tranzakcióinak összesítése fizetési mód alapján."
    class="!mb-0"
    v2
  >
    <template #headerActions>
      <div class="flex mt-5 lg:mt-0">
        <div class="sm-btn-group">
          <button
            class="sm-btn sm-btn-white sm-btn-xs"
            :class="{'sm-btn-primary': selected === 'daily'}"
            @click="setSelected('daily')">
            {{ $t('Műszak') }}
          </button>
          <button
            class="sm-btn sm-btn-white sm-btn-xs"
            :class="{'sm-btn-primary': selected === 'monthly'}"
            @click="setSelected('monthly')">
            {{ $t('Havi') }}
          </button>
        </div>
      </div>
    </template>

    <div class="flex-wrap sm:flex sm-row">
      <div
        class="w-full my-4 sm-media sm-col md:my-0"
        :class="$page.props.permissions.canUseTransfer ? 'md:w-4/12' : 'md:w-6/12'">
        <info-card
          large
          :loading="isLoading"
          :data="{
            color: 'teal',
            icon: 'dollar-sign',
            name: $t('Készpénz'),
            data: cashFlow,
          }" />
      </div>
      <div
        class="w-full my-4 sm-media sm-col md:my-0"
        :class="$page.props.permissions.canUseTransfer ? 'md:w-4/12' : 'md:w-6/12'">
        <info-card
          large
          :loading="isLoading"
          :data="{
            color: 'pink',
            icon: 'credit-card',
            name: $t('Bankkártya'),
            data: cardFlow,
          }" />
      </div>
      <div
        class="w-full my-4 sm-media sm-col md:my-0"
        :class="$page.props.permissions.canUseTransfer ? 'md:w-4/12' : 'md:w-6/12'">
        <info-card
          large
          :loading="isLoading"
          :data="{
            color: 'blue',
            icon: 'repeat',
            name: $t('Átutalás'),
            data: transferFlow,
          }" />
      </div>
    </div>
  </sm-card>
</template>

<script setup>
import InfoCard from "@/inertia/pages/reports/_partials/InfoCard.vue";
import { usePriceFormat } from "@/inertia/composables/global";
import { router, usePage } from "@inertiajs/vue3";
import { onMounted } from "vue";

const page = usePage();

const selected = ref("daily");

const isLoading = ref(true);

const setSelected = (e) => {
  selected.value = e;
  if (e === "monthly" && !page.props.monthlyCashFlow) {
    isLoading.value = true;
    router.reload({
      only: ["monthlyCashFlow"],
      onSuccess: () => {
        isLoading.value = false;
      }
    }, { preserveState: true });
  }
  if (e === "daily" && !page.props.dailyCashFlow) {
    isLoading.value = true;
    router.reload({
      only: ["dailyCashFlow"],
      onSuccess: () => {
        isLoading.value = false;
      }
    }, { preserveState: true });
  }
};

onMounted(() => {
  router.reload({
    only: ["dailyCashFlow"],
    onSuccess: () => {
      isLoading.value = false;
    }
  }, { preserveState: true });
});

const cashFlow = computed(() => {
  const field = selected.value === "daily" ? "dailyCashFlow" : "monthlyCashFlow";
  const money = page.props[field]?.cash;

  if (! money) {
    return "-";
  }

  return usePriceFormat(money);
});

const cardFlow = computed(() => {
  const field = selected.value === "daily" ? "dailyCashFlow" : "monthlyCashFlow";
  const money = page.props[field]?.card;

  if (! money) {
    return "-";
  }

  return usePriceFormat(money);
});

const transferFlow = computed(() => {
  const field = selected.value === "daily" ? "dailyCashFlow" : "monthlyCashFlow";
  const money = page.props[field]?.transfer;

  if (! money) {
    return "-";
  }

  return usePriceFormat(money);
});
</script>
