<template>
  <div>
    <In-Head :title="$t('services.create.title')" />

    <sub-header :title="$t('services.create.title')">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
    </sub-header>

    <sm-card>
      <div class="sm-row">
        <div class="sm-col sm-col-half">
          <sm-input
            v-model="form.name"
            name="name"
            :label="$t('services.create.form.name.label')"
          />
        </div>
        <div class="sm-col sm-col-half">
          <sm-async-select-2
            v-model="form.coach_id"
            name="coach_id"
            search-on-focus
            :label="$t('services.create.form.coach.label')"
            :route="$page.props.routes.coaches" />
        </div>
        <div class="sm-col sm-col-half">
          <sm-input
            v-model="form.price"
            name="price"
            :label="$t('services.create.form.price.label')"
          />
        </div>
        <div class="sm-col sm-col-half">
          <sm-input
            v-model="form.length_in_minutes"
            name="length_in_minutes"
            :label="$t('services.create.form.length_in_minutes.label')"
          >
            <template #append>
              {{ $t('services.create.form.length_in_minutes.append') }}
            </template>
          </sm-input>
        </div>
        <div class="sm-col sm-col-half">
          <sm-input
            v-model="form.capacity"
            name="capacity"
            :label="$t('services.create.form.capacity.label')"
            :help="$t('services.create.form.capacity.help')"
          >
            <template #append>
              {{ $t('services.create.form.capacity.append') }}
            </template>
          </sm-input>
        </div>
      </div>

      <hr class="mt-5 mb-10">

      <div class="sm-row">
        <div class="w-full sm-col">
          <sm-checkbox
            v-model="form.online_booking_enabled"
            name="online_booking_enabled"
            :label="$t('services.create.form.online_booking_enabled.label')"
            :help="$t('services.create.form.online_booking_enabled.help')"
            inline
          />
        </div>
        <div class="w-full sm-col">
          <sm-checkbox
            v-model="form.is_multiple_booking_with_ticket_allowed"
            name="is_multiple_booking_with_ticket_allowed"
            :label="$t('services.create.form.is_multiple_booking_with_ticket_allowed.label')"
            :help="$t('services.create.form.is_multiple_booking_with_ticket_allowed.help')"
            inline
          />
        </div>
        <div class="w-full sm-col">
          <sm-checkbox
            v-model="form.pricing_per_hour"
            name="pricing_per_hour"
            :label="$t('services.create.form.pricing_per_hour.label')"
            :help="$t('services.create.form.pricing_per_hour.help')"
            inline
          />
        </div>
        <div class="w-full sm-col">
          <sm-checkbox
            v-model="form.visible_for_coach_timetables"
            name="visible_for_coach_timetables"
            :label="$t('services.create.form.visible_for_coach_timetables.label')"
            :help="$t('services.create.form.visible_for_coach_timetables.help')"
            inline
          />
        </div>
        <div class="w-full sm-col">
          <sm-checkbox
            v-model="form.delete_on_cancel"
            name="delete_on_cancel"
            :label="$t('services.create.form.delete_on_cancel.label')"
            :help="$t('services.create.form.delete_on_cancel.help')"
            inline
          />
        </div>
      </div>

      <hr class="mt-5 mb-16">

      <div class="sm-row">
        <div class="w-full sm-col">
          <div class="sm-row">
            <sm-checkbox
              v-model="form.is_massage"
              name="is_massage"
              :label="$t('services.create.form.is_massage.label')"
              inline
              half
              inline-left-style="lg:!w-8/12"
              inline-right-style="lg:!w-4/12 pl-4"
            />

            <div class="sm-col sm-col-half -mt-label">
              <sm-async-select-2
                v-model="form.massage_zone_id"
                name="massage_zone_id"
                :disabled="!form.is_massage"
                search-on-focus
                :label="$t('services.create.form.massage_zone_id.label')"
                :route="$page.props.routes.zones" />
            </div>
          </div>
        </div>
      </div>

      <hr class="mt-5 mb-10">

      <div class="sm-row">
        <div class="sm-col sm-col-half">
          <sm-input
            v-model="form.description"
            name="description"
            :label="$t('services.create.form.description.label')"
            type="textarea"
            rows="6"
          />

          <div class="relative">
            <sm-label :label="$t('services.create.form.location_address.map.label')" />
            <GMapMap
              ref="mapRef"
              :center="marker ? marker : defaultMarker"
              :zoom="17"
              map-type-id="terrain"
              class="w-full h-[150px]"
              :options="{
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                animate: true
              }"
              @click="onMapClick"
            >
              <GMapMarker
                v-if="marker"
                :position="marker"
                :draggable="true"
                @drag="onDrag"
              />
            </GMapMap>
            <div
              class="sm-feedback">
              {{ $t('services.create.form.location_address.map.help') }}
            </div>
          </div>
        </div>
        <div class="sm-col sm-col-half">
          <sm-file
            v-model="form.cover_photo"
            name="cover_photo"
            :label="$t('services.create.form.cover_photo.label')"
            :help="$t('services.create.form.cover_photo.help', {width: $page.props.thumbnail.width, height: $page.props.thumbnail.height})"
          />
          <sm-select
            v-model="form.color"
            name="color"
            :options="$page.props.colors"
            :label="$t('services.create.form.color.label')"
          >
            <template #option="option">
              <div class="flex items-center">
                <div
                  class="inline-block w-4 h-4 mr-2 rounded"
                  :style="`background-color:${option.label}`" />
                {{ option.label }}
              </div>
            </template>
            <template #selected-option="option">
              <div class="flex items-center">
                <div
                  class="inline-block w-4 h-4 mr-2 rounded"
                  :style="`background-color:${option.label}`" />
                {{ option.label }}
              </div>
            </template>
          </sm-select>

          <div class="mb-4">
            <sm-label :label="$t('services.create.form.location_address.label')" />
            <GMapAutocomplete
              :value="form.address"
              :options="{
                componentRestrictions: { country: 'hu' },
              }"
              class="sm-input-text"
              @place_changed="setPlace"
            />
            <div
              class="sm-feedback">
              {{ $t('services.create.form.location_address.help') }}
            </div>
          </div>
          <sm-select
            v-model="form.tags"
            name="tags"
            multiple
            :options="activityTypes"
            :label="$t('services.create.form.tags.label')"
          />
        </div>
      </div>
    </sm-card>

    <details class="mt-4">
      <summary class="mb-4">
        <span class="text-lg">{{ $t('services.create.others.title') }}</span>
      </summary>

      <sm-card
        v-if="$page.props.permissions.canTicket"
        :header-title="$t('services.create.others.tickets.title')">
        <div class="sm-row">
          <div class="w-8/12 sm-col md:w-4/12">
            <sm-async-select-2
              v-model="copyService"
              name="copy_from_service"
              search-on-focus
              :label="$t('services.create.form.copy_from_service.label')"
              :route="$page.props.routes.services" />
          </div>
          <div class="sm-col pt-label">
            <sm-confirm-button
              :confirm-title="$t('services.create.others.tickets.copy_confirmation')"
              size="large"
              color="primary"
              name="copy_confirmation"
              :disabled="!copyService"
              :title="$t('generic.copy')"
              icon="copy"
              @onAccept="copyFromUserService" />
          </div>
        </div>
        <div class="sm-row">
          <div class="w-8/12 sm-col md:w-4/12">
            <sm-async-select-2
              :model-value="searchResult?.id"
              name="tickets"
              search-on-focus
              :label="$t('services.create.form.tickets.label')"
              :route="$page.props.routes.tickets"
              :clearable="false"
              @option-selected="searchResult = $event"
            />
          </div>
          <div class="sm-col pt-label">
            <div class="flex space-x-2">
              <sm-button
                size="input"
                :title="$t('services.create.form.tickets.add')"
                icon="plus-circle"
                :disabled="!searchResult"
                @click="addSeasonTicket" />
              <sm-delete-button
                :title="$t('services.create.form.tickets.delete_all')"
                :preset="null"
                name="deleteAllTickets"
                size="sm-btn-input"
                color="sm-btn-danger"
                icon="trash-2"
                :disabled="!tickets.length"
                @onDelete="deleteAllTickets"
              />
            </div>
          </div>
        </div>
        <div
          v-if="tickets.length"
          class="sm-row">
          <div class="w-full sm-col md:w-1/2">
            <sm-table
              :table-data="tickets"
              table-heading-key="services.create.others.tickets.table"
              :table-columns="ticketsTableColumns">
              <template #actions="slotProps">
                <div class="flex justify-end">
                  <sm-delete-button
                    :name="`deleteDiscout_${slotProps.data.id}`"
                    @onDelete="deleteSeasonTicket(slotProps.data)"
                  />
                </div>
              </template>
            </sm-table>
          </div>
        </div>
      </sm-card>

      <sm-card
        :header-title="$t('services.create.others.edits.title')">
        <sm-alert color="light">
          {{ $t('services.create.others.edits.info') }}
        </sm-alert>

        <sm-checkbox
          v-model="form.coaches_can_edit"
          name="coaches_can_edit"
          :label="$t('services.create.form.coaches_can_edit.label')"
          :help="$t('services.create.form.coaches_can_edit.help')"
        />

        <sm-async-select-2
          v-model="form.editors"
          name="editors"
          search-on-focus
          multiple
          :label="$t('services.create.form.editors.label')"
          :route="$page.props.routes.editors" />

        <div
          v-for="role in $page.props.roles"
          :key="role.id">
          <sm-checkbox
            :name="`roles[${role.id}]`"
            :label="role.name"
            inline
            class="mb-0"
            :model-value="roles.includes(role.id)"
            @update:modelValue="onRoleChange(role.id)"
          />
        </div>
      </sm-card>
    </details>

    <sm-card>
      <sm-button
        preset="save"
        :loading="form.processing"
        @click="save" />
    </sm-card>
  </div>
</template>

<script setup>
import { useDebounceFn, useFetch } from "@vueuse/core";
import axios from "axios";

import { useFormatSelect } from "@/inertia/composables/global";
import { useForm, usePage } from "@inertiajs/vue3";
import { provide } from "vue";
import { useToast } from "vue-toastification";

const page = usePage();
const toast = useToast();

const activityTypes = useFormatSelect(page.props.activityTypes);

const form = useForm({
  name: page.props.service.name || null,
  coach_id: page.props.service.coach_id || null,
  price: page.props.service.price || null,
  length_in_minutes: page.props.service.length_in_minutes || null,
  capacity: page.props.service.capacity || null,
  online_booking_enabled: page.props.service.online_booking_enabled || false,
  is_multiple_booking_with_ticket_allowed: page.props.service.is_multiple_booking_with_ticket_allowed || false,
  pricing_per_hour: page.props.service.pricing_per_hour || false,
  visible_for_coach_timetables: page.props.service.visible_for_coach_timetables || false,
  delete_on_cancel: page.props.service.delete_on_cancel || false,
  is_massage: page.props.service.is_massage || false,
  massage_zone_id: page.props.service.massage_zone_id || null,
  description: page.props.service.description || null,
  cover_photo: page.props.service.cover_photo || null,
  color: page.props.service.color || null,
  tags: page.props.service.tags ? page.props.service.tags.map(data => activityTypes.find(at => at.id === data)) : [],
  //
  coaches_can_edit: page.props.service.coaches_can_edit || false,
  editors: page.props.editors,

  latitude: page.props.service.latitude,
  longitude: page.props.service.longitude,
  address: page.props.service.address,
});

provide("form", form);

const ticketsTableColumns = ref(["name", "actions"]);

const tickets = ref(page.props.ticketConstraints);
const searchResult = ref(null);
const copyService = ref(null);
const roles = ref(page.props.service.roles ?? []);

const mapRef = ref();

const marker = computed(() => {
  if (! form.latitude) {
    return null;
  }

  return {
    lat: form.latitude,
    lng: form.longitude,
  };
});

const defaultMarker = computed(() => {
  return {
    lat: page.props.clubLocation.latitude ?? 47.1535881,
    lng: page.props.clubLocation.longitude ?? 18.1864216
  };
});

function addSeasonTicket() {
  if (searchResult.value && !tickets.value.some(el => el.id === searchResult.value.id)) {
    let seasonTicket = {id: searchResult.value.id, name: searchResult.value.label};
    tickets.value = tickets.value.concat([seasonTicket]);
  } else {
    toast.error(window.trans("services.create.others.tickets.ticket_exists", {ticket: searchResult.value.label}));
  }

  searchResult.value = null;
}

function deleteSeasonTicket(deletedSeasonTicket) {
  tickets.value = tickets.value.filter((seasonTicket) => seasonTicket.id !== deletedSeasonTicket.id);
}

async function copyFromUserService() {
  try {
    const url = `${page.props.routes.constraintTickets}?service=${copyService.value}`;

    const response = await axios.get(url);
    tickets.value = response.data.data.map(item => ({
      id: item.id,
      name: item.name,
    }));

    copyService.value = null;
  } catch (e) {
    toast.error(window.trans("Hiba a keresés elvégzése közben."));
  }
}

function deleteAllTickets() {
  if (! tickets.value.length) {
    return;
  }

  tickets.value = [];
  copyService.value = null;
  searchResult.value = null;
}

function onRoleChange(id) {
  if (roles.value.find(data => data === id)) {
    roles.value = roles.value.filter(data => data !== id);
  } else {
    roles.value = roles.value.concat([id]);
  }
}

function transformFormData(data) {
  // default
  let formData = {
    ...data,
    tags: data.tags.map(data => data.id)
  };

  // tickets
  if (tickets.value.length) {
    formData = {
      ...formData,
      tickets: tickets.value.map(data => data.id)
    };
  }

  // edits
  if (data.coaches_can_edit) {
    formData = {
      ...formData,
      roles: roles.value.map(i => parseInt(i)),
    };
  }

  if (page.props.service.id) {
    formData = {
      ...formData,
      _method: "put",
    };
  }
  return formData;
}

function save() {
  form
    .transform(data => transformFormData(data))
    .post(page.props.routes.save);
}

async function getLocation(e) {
  const lat = e.latLng.lat();
  const lng = e.latLng.lng();
  form.latitude = lat;
  form.longitude = lng;

  const { data } = await useFetch(`${page.props.routes.geocode}?lat=${lat}&lng=${lng}`).get().json();

  if (data?.value.status === "OK") {
    const { results } = data.value;
    form.address = results[0].formatted_address;
  }
}

function onMapClick(e) {
  getLocation(e);
}

const onDrag = useDebounceFn((e) => {
  getLocation(e);
}, 300);

function setPlace(e) {
  form.latitude = e.geometry.location.lat();
  form.longitude = e.geometry.location.lng();

  form.address = e.formatted_address;
}

</script>
