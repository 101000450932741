<template>
  <div class="absolute right-0 p-2 bg-white -bottom-[47px] lg:bottom-0 lg:p-0 lg:pr-4 lg:relative border-b border-l border-brand-gray-200 lg:border-0">
    <span class="absolute h-[30px] w-px right-0 top-1/2 -translate-y-1/2 bg-brand-gray-200 hidden lg:block" />
    <div class="flex items-center">
      <p class="hidden lg:block mb-0 text-xs text-brand-gray-800 whitespace-nowrap mr-1.5">
        {{ $t('Bent lévő vendégek') }}:
      </p>
      <p>
        <user-icon
          class="block w-5 lg:hidden mr-1.5" />
      </p>
      <div class="flex items-center space-x-2">
        <div
          class="text-xl cursor-pointer border rounded-lg flex items-center justify-center hover:border-bo-gray-400 transition-colors min-w-[30px] h-[30px] text-center select-none"
          @click="openModal({ increase: false })">
          -
        </div>
        <p class="flex-1 text-xs font-semibold text-center uppercase text-brand-gray-800 min-w-[30px]">
          {{ checkedInUsers }} {{ $t('fő') }}
        </p>
        <div
          class="text-xl cursor-pointer border rounded-lg flex items-center justify-center hover:border-bo-gray-400 transition-colors min-w-[30px] h-[30px] text-center select-none"
          @click="openModal({ increase: true })">
          +
        </div>
      </div>
    </div>
    <portal to="modals">
      <sm-modal
        :modal-is-visible="modalOpen"
        :modal-title="actionIncrease ? $t('Belépett vendégek rögzítése') : $t('Kilépett vendégek rögzítése')"
        @toggleModalVisibility="toggleModal">
        <div
          class="sm-form-group"
          :class="{'has-error': form.errors.value}"
        >
          <label class="sm-label">{{ $t('Vendégek száma') }}</label>

          <input
            ref="valueInputEl"
            v-model="form.value"
            class="text-sm sm-input-text"
            type="number"
            min="0"
            step="1"
            @keyup.enter="submit"
          >

          <template v-if="form.errors.value">
            <div class="sm-feedback error">
              {{ form.errors.value }}
            </div>
          </template>
        </div>
        <template #footer>
          <button
            class="sm-btn sm-btn-xs"
            @click="toggleModal">
            {{ $t('generic.cancel') }}
          </button>
          <button
            class="sm-btn sm-btn-xs sm-btn-primary"
            @click="submit">
            {{ $t('generic.save') }}
          </button>
        </template>
      </sm-modal>
    </portal>
  </div>
</template>

<script setup>
import { useForm } from "@inertiajs/vue3";
import axios from "axios";
import { ref } from "vue";
import {
  UserIcon
} from "vue-feather-icons";
import SmModal from "../../inertia/components/base/SmModal.vue";

const props = defineProps({
  initial: { type: Number, required: false, default: 0 },
  submitRoute: { type: String, required: true },
});

const checkedInUsers = ref(props.initial);
const modalOpen = ref(false);
const actionIncrease = ref(false);
const valueInputEl = ref(null);

const form = useForm({
  value: 1,
});

const toggleModal = () => {
  modalOpen.value = !modalOpen.value;
};

const openModal = ({ increase }) => {
  actionIncrease.value = increase;
  form.reset();
  toggleModal();
};

const closeModal = () => {
  toggleModal();
};

watchEffect(() => {
  if (valueInputEl.value) {
    valueInputEl.value.focus();
  }
});

const submit = async () => {
  try {
    const response = await axios.post(props.submitRoute, {
      value: actionIncrease.value ? form.value : (-1 * form.value)
    });
    checkedInUsers.value = response.data.data.value;

    closeModal();
  } catch (e) {
    console.error(e);

    if (e.response.data.errors.value) {
      form.setError("value", e.response.data.errors.value[0]);
    }
  }
};
</script>
