<template>
  <div>
    <sm-modal
      :modal-is-visible="modal"
      size="lg"
      :modal-title="$t('transactions.pick_invoice_modal.title')"
      body-classes="!p-0"
      close-button
      @toggleModalVisibility="toggleModalVisibility">
      <sm-table
        :table-data="tableData"
        table-heading-key="transactions.pick_invoice_modal.table"
        :table-columns="tableColumns">
        <template #actions="slotProps">
          <div class="flex">
            <sm-button
              class="flex-1 justify-center"
              :title="$t('transactions.pick_invoice_modal.submit')"
              color="primary"
              :loading="form.processing"
              @click="onSubmit(slotProps.data)" />
          </div>
        </template>
      </sm-table>
      <template #footer>
        <sm-button
          :title="$t('generic.cancel')"
          @click="toggleModalVisibility" />
      </template>
    </sm-modal>
    <sm-button
      icon="plus"
      size="icon"
      :title="$t('transactions.show.create_invoice')"
      @click="toggleModalVisibility"
    />
  </div>
</template>

<script setup>
import { useForm, usePage, router } from "@inertiajs/vue3";

const props = defineProps({
  showInvoiceModal: { type: Boolean, required: false, default: null }
});

const emits = defineEmits(["triggerShowInvoiceModal"]);

const page = usePage();
const form = useForm({});

const tableColumns = ref(["name", "email", "phone", "postal_code", "city", "street"]);
const tableData = computed(() => page.props.invoicingDataList ?? []);

const modal = ref(false);

const toggleModalVisibility = () => {
  modal.value = !modal.value;
};

watch(props, (e) => {
  if (e.showInvoiceModal) {
    // toggleModalVisibility();
  }
});

watch(modal, (e) => {
  if (!e) {
    emits("triggerShowInvoiceModal");
  } else {
    router.reload({ only: ["invoicingDataList"] });
  }
});

const onSubmit = (data) => {
  form.transform(() => ({
    invoice_data_id: data.id,
  }))
    .post(page.props.transaction.routes.create_invoice, {
      only: ["transaction", "errors", "flash"],
      preserveState: true,
      onSuccess: () => {
        toggleModalVisibility();
      }
    });
};
</script>
