<template>
  <div v-auto-animate>
    <div v-if="!isLoading">
      <Line
        id="my-chart-id"
        :options="chartOptions"
        :data="localChartData"
        :plugins="[htmlLegendPlugin]"
        :style="chartStyles"
      />
    </div>
    <div
      v-else
      class="flex items-center justify-center w-full h-[283px]">
      <vue-feather
        type="rotate-cw"
        class="animate-spin"
        size="18px"
      />
    </div>
  </div>
</template>

<script setup>
import { htmlLegendPlugin, useGenerateGradient } from "@/inertia/composables/charts";
import { Chart as ChartJS, Filler, Legend } from "chart.js";
import { addDays, format, startOfWeek } from "date-fns";
import {
  Line
} from "vue-chartjs";

const props = defineProps({
  globalChartConfig: { type: Object, required: false, default: null },
  chartData: { type: Object, required: false, default: null },
  isLoading: { type: Boolean, required: false, default: false }
});

ChartJS.register(
  Filler,
  Legend
);

const chartStyles = inject("chartStyles");

const localChartData = computed(() => {
  return {
    labels: (props.chartData?.current || []).map(v => v.x),
    responsive: true,
    datasets: [
      {
        label: window.trans("dashboard.charts.weekly.current"),
        data: (props.chartData?.current || []).map(v => v.y),
        fill: true,
        borderColor: "#3E97FF",
        borderWidth: 2,
        backgroundColor: function(context) {
          const {chart} = context;
          const {ctx, chartArea} = chart;
          if (!chartArea) {
            return;
          }
          return useGenerateGradient(ctx, chartArea);
        },
      },
      {
        label: window.trans("dashboard.charts.weekly.comparison"),
        data: (props.chartData?.comparison || []).map(v => v.y),
        backgroundColor: "#DC3545",
        borderColor: "#DC3545",
        borderWidth: 2,
      },
    ]
  };
});

const chartOptions = ref({
  ...props.globalChartConfig,
});
</script>

