<template>
  <button
    v-if="activeNews"
    class="inline-flex items-center space-x-1 text-xs rounded cursor-pointer"
    @click="toggleModalVisibility">
    <StarIcon
      class="text-bo-yellow-600 !w-4 !h-4"
      stroke-width="2.5" />
    <span class="font-semibold text-bo-blue-200 mt-0.5 hidden lg:block">
      <span>{{ $t('Újdonságok') }}</span>
    </span>
  </button>
  <portal to="modals">
    <transition name="fade">
      <div
        v-if="modalIsVisible"
        class="fixed top-0 left-0 w-full h-full hidden overflow-hidden outline-0 z-[1050] transition-all opacity-0"
        :class="{'opacity-100 overflow-y-auto !block': modalIsVisible}">
        <button
          type="button"
          class="fixed top-0 left-0 w-full h-full bg-black/30"
          @click="toggleModalVisibility" />
      </div>
    </transition>
    <transition name="system-modal">
      <div
        v-if="modalIsVisible"
        class="fixed z-[10000] mx-auto overflow-hidden bg-white rounded-lg top-10 translate-x-1/2 right-1/2 origin-top-right "
        :style="{ width: `${sliderWidth}px` }"
      >
        <div class="">
          <div
            ref="slider"
            :style="`transform:translateX(${sliderPosition}px)`"
            class="flex transition-transform ease-in-out">
            <div
              v-for="(article, index) in props.activeNews.slides"
              :key="index"
              :style="{ minWidth: `${sliderWidth}px` }">
              <div class="max-h-[calc(100vh-10rem)] overflow-y-auto">
                <button
                  type="button"
                  target="_blank"
                  @click="showSingle(article.image)">
                  <img
                    :src="article.image"
                    :alt="article.title"
                    class="w-full"
                  >
                </button>
                <div class="px-5 pt-5 bg-white">
                  <article>
                    <h2 class="mb-1 text-base leading-7 text-bo-blue font-roboto-700">
                      {{ article.title }}
                    </h2>
                    <div
                      class="text-sm font-roboto text-brand-gray-900 news-content"
                      v-html="article.description" />
                  </article>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          v-if="isOnLastPage"
          class="absolute p-1 rounded-full cursor-pointer top-4 right-4 bg-black/10 h-7"
          type="button"
          @click="toggleModalVisibility">
          <vue-feather
            class="text-white opacity-85"
            type="x"
            size="20"
          />
        </button>
        <footer class="flex items-center justify-between p-5">
          <p class="text-xs font-bold text-bo-gray-400">
            {{ activePage }} / {{ props.activeNews.slides.length }}
          </p>
          <div class="flex space-x-2.5">
            <button
              type="button"
              class="px-4 py-2 text-xs font-bold leading-4 transition-opacity border rounded-lg text-bo-gray-800 border-bo-gray-200"
              :class="{'opacity-50 pointer-events-none': !prevIsEnabled}"
              @click="prev">
              Előző
            </button>
            <button
              type="button"
              class="px-4 py-2 text-xs font-bold leading-4 text-white transition-opacity border rounded-lg border-bo-blue bg-bo-blue"
              @click="next">
              <template v-if="isOnLastPage">
                Bezárás
              </template>
              <template v-else>
                Következő
              </template>
            </button>
          </div>
        </footer>
      </div>
    </transition>
    <vue-easy-lightbox
      :visible="imageModalIsVisible"
      :imgs="imageModalImage"
      :index="imageModalIndex"
      @hide="onHideImageModal"
    />
  </portal>
</template>

<script setup>
import { useWindowSize } from "@vueuse/core";
import { computed, watch } from "vue";
import VueEasyLightbox from "vue-easy-lightbox";
import { StarIcon } from "vue-feather-icons";


const props = defineProps({
  activeNews: { type: Object, required: false, default: null },
});

/* Generic */
const { width: windowWidth } = useWindowSize();

/* Modal */
const modalIsVisible = ref(false);
const toggleModalVisibility = () => modalIsVisible.value = !modalIsVisible.value;

/* Slider */
const activePage = ref(1);
const slider = ref();
const sliderPosition = ref(0);
// const sliderWidth = ref(windowWidth.value = 768 ? 600 : 320);

const sliderWidth = computed(() => {
  return windowWidth.value >= 768 ? 600 : 320;
});

const prevIsEnabled = computed(() => {
  if (isOnFirstPage.value) {
    return false;
  }

  return activePage.value > 1;
});

const nextIsEnabled = computed(() => {
  if (isOnLastPage.value) {
    return false;
  }

  return activePage.value < props.activeNews.slides.length;
});

const prev = () => {
  if (prevIsEnabled.value) {
    activePage.value--;
    sliderPosition.value = sliderPosition.value + sliderWidth.value;
  }
};

const next = () => {
  if (isOnLastPage.value) {
    toggleModalVisibility();
    return;
  }

  if (nextIsEnabled.value) {
    activePage.value++;
    sliderPosition.value = sliderPosition.value - sliderWidth.value;
  }
};

const isOnFirstPage = computed(() => {
  return activePage.value === 1;
});

const isOnLastPage = computed(() => {
  return activePage.value === props.activeNews.slides.length;
});

const reset = () => {
  activePage.value = 1;
  sliderPosition.value = 0;
};

watch(modalIsVisible, (newValue, oldValue) => {
  if (newValue) {
    localStorage.setItem("lastReadSystemNews", props.activeNews.id);
  } else {
    // Reset the initial slider
    reset();
  }
});

onMounted(() => {
  if (! props.activeNews) {
    return;
  }

  const lastReadId = localStorage.getItem("lastReadSystemNews");

  if (lastReadId) {
    if (parseInt(lastReadId) !== props.activeNews.id) {
      setTimeout(() => toggleModalVisibility(), 500);
    }
  } else if (props.activeNews.id) {
    setTimeout(() => toggleModalVisibility(), 500);
  }
});


const imageModalIsVisible = ref(false);
const imageModalIndex = ref(0); // default 0
const imageModalImage = ref([]);

const showSingle = (src) => {
  imageModalImage.value = src;
  imageModalIsVisible.value = true;
};

const onHideImageModal = () => (imageModalIsVisible.value = false);

</script>

<style lang="postcss" scoped>
.vel-modal {
  @apply z-[10001];
}
.system-modal {
  &-enter-active,
  &-leave-active {
    @apply transition-all duration-500;
  }

  &-enter-from,
  &-leave-to {
    @apply opacity-0 scale-50 right-[230px] translate-x-0 top-0;
  }

  &-enter-to,
  &-leave-from {
    @apply opacity-100 scale-100 translate-x-1/2 right-1/2 top-10;
  }
}

.news-content {
  :deep(p) {
    @apply mb-2;
  }
}
</style>
