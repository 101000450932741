<template>
  <sm-card
    no-padding
    header-title="Legújabb vendégek"
    class="!mb-0"
    v2
  >
    <template #headerActions>
      <button
        type="button"
        class="sm-link leading-[0] text-lg"
        @click="refresh">
        <rotate-cw-icon
          class="w-4"
          stroke-width="2.5" />
      </button>
    </template>

    <div v-auto-animate>
      <ul
        v-if="loaded"
        class="mb-0 text-sm border-0 sm-list-group sm-list-group-flush">
        <li
          v-for="customer in customers"
          :key="customer.id"
          class="p-0 sm-list-group-item">
          <Link
            :href="customer.profile_url"
            class="flex w-full px-6 py-2.5 text-bo-blue">
            <div class="sm-avatar sm-avatar-sm">
              <img
                :src="customer.avatar"
                alt="customer avatar"
                class="rounded">
            </div>
            <div class="pl-2.5">
              <p class="mb-0">
                {{ customer.name }}
              </p>
              <small class="mb-0 text-xs text-bo-gray-400">{{ useDateFormat(customer.date_of_birth) }}</small>
            </div>
            <div class="flex self-center ml-auto">
              <span class="sm:block">
                <UserIcon class="w-6" />
              </span>
            </div>
          </Link>
        </li>
      </ul>
      <div
        v-else
        class="flex items-center justify-center w-full h-10">
        <vue-feather
          type="rotate-cw"
          class="animate-spin"
          size="18px"
        />
      </div>
    </div>

    <div class="py-4 text-sm text-center border-t rounded-b-lg card-footer bg-bo-gray-100/25 border-bo-gray-200">
      <Link
        :href="$page.props.routes.customers"
        class="flex items-center justify-center space-x-2 sm-link">
        <span>{{ $t('További ügyfelek') }}</span>
        <ArrowDownIcon class="w-4" />
      </Link>
    </div>
  </sm-card>
</template>

<script setup>
import { useDateFormat } from "@/inertia/composables/global";
import { Link, usePage } from "@inertiajs/vue3";
import axios from "axios";
import { onMounted, ref } from "vue";
import { ArrowDownIcon, RotateCwIcon, UserIcon } from "vue-feather-icons";
import { useToast } from "vue-toastification";

const page = usePage();
const customers = ref([]);
const toast = useToast();

const loaded = ref(false);

const loadData = async () => {
  try {
    const url = `${page.props.routes.latestCustomers}`;
    const response = await axios.get(url);
    customers.value = response.data.data;
    loaded.value = true;
  } catch (errors) {
    toast.error(errors.message);
    console.error(errors);
    loaded.value = true;
  }
};

const refresh = () => {
  loadData();
};

onMounted(() => {
  loadData();
});
</script>
