<template>
  <sm-card :header-title="$t('customers.discounts_page.grouped_discounts.title')">
    <div class="sm-row">
      <div class="w-full sm-col lg:w-4/12">
        <sm-async-select
          v-model="form.discount_group"
          name="discount_group"
          :label="$t('customers.discounts_page.grouped_discounts.fields.discount_group.label')"
          :route="$page.props.routes.discounts" />
      </div>
      <div class="w-full sm-col lg:w-4/12">
        <sm-date-picker-date
          v-model="form.discount_end_date"
          name="discount_end_date"
          :label="$t('customers.discounts_page.grouped_discounts.fields.discount_end_date.label')"
          :help="$t('customers.discounts_page.grouped_discounts.fields.discount_end_date.help')"
        />
      </div>
      <div class="flex-1 sm-col lg:pt-label">
        <sm-button
          preset="add"
          color="secondary"
          class="sm-btn-input"
          :loading="form.processing"
          :disabled="!form.discount_group"
          @click="addDiscountGroup" />
      </div>
    </div>
    <table
      class="sm-table">
      <thead>
        <tr>
          <th>{{ $t('customers.discounts_page.grouped_discounts.table.name') }}</th>
          <th>{{ $t('customers.discounts_page.grouped_discounts.table.end_date') }}</th>
          <th />
        </tr>
      </thead>
      <tbody
        v-if="discountGroups.length !== 0">
        <tr
          v-for="discount in discountGroups"
          :key="discount.id">
          <td>
            {{ discount.name }}
          </td>
          <td>
            {{ discount.end_date ? useDateFormat(discount.end_date, 'form') : '-' }}
          </td>
          <td class="text-right">
            <sm-delete-button
              :name="`deleteDiscout_${discount.id}`"
              @onDelete="deleteDiscountGroup(discount)"
            />
          </td>
        </tr>
      </tbody>
      <empty-table
        v-else
        colspan="3" />
    </table>
    <sm-confirm-dialog
      :group="`discountAdded_${uid}`"
      cancel-label="Ok"
      :has-accept-button="false" />
  </sm-card>
</template>

<script setup>
import { useDateFormat } from "@/inertia/composables/global";
import { useForm, usePage } from "@inertiajs/vue3";
import uniqueId from "lodash.uniqueid";
import { useConfirm } from "primevue/useconfirm";
import { onMounted, watch } from "vue";

const confirm = useConfirm();
const page = usePage();
const emit = defineEmits(["updateForm"]);

const discountGroups = ref(page.props.discountGroups);
const newDiscountGroup = ref({});

const form = useForm({
  discount_group: null,
  discount_end_date: null
});

const uid = uniqueId();

provide("form", form);

const addDiscountGroup = () => {
  if (!form.discount_group) {
    return;
  }

  newDiscountGroup.value = {
    id: form.discount_group.id,
    name: form.discount_group.label,
    end_date: form.discount_end_date,
  };

  if (discountGroups.value.find(data => data.id === newDiscountGroup.value.id)) {
    confirm.require({
      group: `discountAdded_${uid}`,
      message: window.trans("customers.discounts_page.grouped_discounts.errors.has_discount")
    });
  } else {
    discountGroups.value.push(newDiscountGroup.value);
    newDiscountGroup.value = {};
    console.log("run");
    form.defaults("discount_group", null);
    form.reset();
  }
};

const deleteDiscountGroup = (deletedDiscountGroup) => {
  discountGroups.value = discountGroups.value.filter(function (discountGroup) {
    return discountGroup !== deletedDiscountGroup;
  });
};

onMounted(() => {
  emit("updateForm", "discountGroups", discountGroups.value);
});

watch(() => discountGroups.value, (e) => {
  emit("updateForm", "discountGroups", e);
}, {deep: true});
</script>
