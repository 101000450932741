<template>
  <sm-card
    v2
    :header-title="$t(`dashboard.charts.title_${activeChart}`)"
    class="!mb-0"
  >
    <template #headerActions>
      <div class="flex items-center space-x-1 sm:space-x-3">
        <sm-button
          :title="$t('dashboard.charts.daily.tab')"
          :color="activeChart === 'daily' ? 'modern sm-btn-modern-active' : 'modern'"
          @click="toggleActiveChart('daily')" />
        <sm-button
          :title="$t('dashboard.charts.weekly.tab')"
          :color="activeChart === 'weekly' ? 'modern sm-btn-modern-active' : 'modern'"
          @click="toggleActiveChart('weekly')" />
        <sm-button
          :title="$t('dashboard.charts.monthly.tab')"
          :color="activeChart === 'monthly' ? 'modern sm-btn-modern-active' : 'modern'"
          @click="toggleActiveChart('monthly')" />
        <sm-date-picker-date
          v-model="datePicker"
          input-style="bg"
          class="mb-0 w-44">
          <template #append>
            <vue-feather
              type="calendar"
              stroke-width="3"
              class="w-4 h-4" />
          </template>
        </sm-date-picker-date>
      </div>
    </template>
    <div class="pt-8">
      <div id="checkin-legend-container" />
      <daily-checkin-chart
        v-if="chartToShow === 'daily'"
        :global-chart-config="globalChartConfig"
        :chart-data="chartData"
        :is-loading="isLoading"
      />
      <weekly-checkin-chart
        v-else-if="chartToShow === 'weekly'"
        :global-chart-config="globalChartConfig"
        :chart-data="chartData"
        :is-loading="isLoading"
      />
      <monthly-checkin-chart
        v-else-if="chartToShow === 'monthly'"
        :global-chart-config="globalChartConfig"
        :chart-data="chartData"
        :is-loading="isLoading"
      />
    </div>
  </sm-card>
</template>

<script setup>
import { usePage } from "@inertiajs/vue3";
import axios from "axios";
import DailyCheckinChart from "./CheckinCharts/DailyCheckinChart.vue";
import MonthlyCheckinChart from "./CheckinCharts/MonthlyCheckinChart.vue";
import WeeklyCheckinChart from "./CheckinCharts/WeeklyCheckinChart.vue";
import { useDateFormat } from "@/inertia/composables/global";

const datePicker = ref(new Date());

const chartStyles = computed(() => {
  return {
    position: "relative",
    height: "283px",
    width: "100%"
  };
});

provide("chartStyles", chartStyles);

const globalChartConfig = ref({
  responsive: false,
  maintainAspectRatio: true,
  plugins: {
    htmlLegend: {
      containerID: "checkin-legend-container",
    },
    legend: {
      display: false,
    },
  },
  elements: {
    point:{
      radius: 0
    }
  },
  scales: {
    x: {
      display: true,
      grid: {
        display: false
      }
    },
    y: {
      display: true,
      suggestedMin: 0,
      suggestedMax: 100,
      ticks: {
        stepSize: 25
      }
    },
  },
});

const activeChart = ref("daily");
const chartToShow = ref("daily");

const toggleActiveChart = (chart) => {
  activeChart.value = chart;
};

const page = usePage();
const chartData = ref(null);
const isLoading = ref(false);

const loadData = async () => {
  const loaderTimeout = setTimeout(() => {
    isLoading.value = true;
  }, 200);

  const response = await axios.get(page.props.routes.checkinChartData, {
    params: {
      interval: activeChart.value,
      date: useDateFormat(datePicker.value, "form"),
    }
  });

  clearTimeout(loaderTimeout);
  isLoading.value = false;
  chartData.value = response.data.data;
  chartToShow.value = chartToShow.value;
};

onMounted(() => {
  loadData();
});

watch(activeChart, () => {
  loadData();
});

watch(datePicker, () => {
  loadData();
});
</script>

<style lang="postcss">
#checkin-legend-container {

  .legend-container {
        @apply space-x-5 w-full flex justify-center;

        li {
            @apply inline-flex items-center justify-between cursor-pointer;

            span {
                @apply block h-5 w-2 rounded mr-1.5;
            }

            p {
                @apply text-sm text-bo-gray-400 font-bold;

                &:last-child {
                    @apply ml-auto;
                }
            }
        }
    }
}
</style>

