<template>
  <div v-auto-animate>
    <div v-if="!isLoading">
      <Bar
        id="my-chart-id"
        :options="chartOptions"
        :data="localChartData"
        :plugins="[htmlLegendPlugin]"
        :style="chartStyles"
      />
    </div>
    <div
      v-else
      class="flex items-center justify-center w-full h-[283px]">
      <vue-feather
        type="rotate-cw"
        class="animate-spin"
        size="18px"
      />
    </div>
  </div>
</template>

<script setup>
import { htmlLegendPlugin } from "@/inertia/composables/charts";
import { Chart as ChartJS, Legend } from "chart.js";
import { eachHourOfInterval, format } from "date-fns";
import {
  Bar
} from "vue-chartjs";

const props = defineProps({
  globalChartConfig: { type: Object, required: false, default: null },
  chartData: { type: Object, required: false, default: null },
  isLoading: { type: Boolean, required: false, default: false }
});

ChartJS.register(
  Legend
);

const barConfig = {
  barThickness: 10,
  borderWidth: 2,
  borderColor: "transparent",
  borderRadius: 5
};

const chartStyles = inject("chartStyles");

const localChartData = computed(() => {
  return {
    labels: (props.chartData?.current || []).map(v => v.x),
    responsive: true,
    datasets: [
      {
        label: window.trans("dashboard.charts.daily.current"),
        data: props.chartData?.current || [],
        fill: true,
        backgroundColor: "#3490DC",
        ...barConfig
      },
      {
        label: window.trans("dashboard.charts.daily.comparison"),
        data: props.chartData?.comparison || [],
        backgroundColor: "#e3e7ed",
        ...barConfig
      },
    ]
  };
});

const chartOptions = ref({
  ...props.globalChartConfig,
});
</script>

