<template>
  <div>
    <In-Head :title="$t('backoffice.pages.support.index')" />

    <sub-header :title="$t('backoffice.pages.support.index')">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
    </sub-header>

    <sm-card no-padding>
      <sm-datatable
        loaded
        :data="table"
        paginator>
        <template #columns>
          <Column
            v-for="(col, index) of tableFields"
            :key="col + '_' + index"
            :field="col"
            :header="$t(`support.tickets.table.${col}`)">
            <template #body="slotProps">
              <template v-if="col === 'created_at' || col === 'updated_at' || col === 'closed_at'">
                {{ slotProps.data?.[col] ? useDateFormat(slotProps.data?.[col], 'date_time') : '-' }}
              </template>
              <template v-else>
                {{ slotProps.data?.[col] }}
              </template>
            </template>
          </Column>
          <Column field="actions">
            <template #body="slotProps">
              <div class="flex justify-end space-x-1">
                <sm-button
                  v-tippy="$t('generic.details')"
                  icon="eye"
                  size="icon"
                  :href="slotProps.data?.routes.show" />
              </div>
            </template>
          </Column>
        </template>
      </sm-datatable>
    </sm-card>
  </div>
</template>

<script setup>
import { useDateFormat } from "@/inertia/composables/global";

defineProps({
  table: { type: Object, required: false, default: () => {} },
  sorts: { type: Object, required: false, default: () => {} },
});

const tableFields = ref([
  "name",
  "status",
  "created_at",
  "updated_at",
  "closed_at",
]);
</script>
