<!-- data.type === "cash" || data.type === "card" || data.type === "szep_card" || data.type === "online" || data.type === "transfer" -->
<template>
  <div>
    <In-Head :title="$t('reports.sold_products.title')" />

    <sub-header
      :title="$t('reports.sold_products.title')"
      :export-route="$page.props.routes.export"
      automatic-export
    >
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
    </sub-header>

    <div
      v-if="$page.props.permissions.canViewIncomeSummary"
      class="flex flex-wrap lg:space-x-2">
      <sm-card
        v2
        header-classes="!mb-0"
        class="flex-grow-0 min-w-[200px] pt-5 !pb-3"
      >
        <div>
          <h6
            class="mb-0 font-semibold leading-normal tracking-wider uppercase text-xxs nowrap text-bo-gray-400 whitespace-nowrap">
            {{ stat.title }}
            <vue-feather
              v-tippy="stat.tooltip"
              type="info"
              class="w-2.5" />
          </h6>
          <p class="flex items-baseline space-x-1 text-xl font-semibold leading-8 text-bo-blue">
            <span>{{ useNumberFormat(stat.amount) }}</span>
            <span
              v-if="stat.helper"
              class="text-sm text-bo-gray-400">{{ stat.helper }}</span>
          </p>
        </div>
      </sm-card>
      <sm-card
        v-if="aggregates"
        v2
        class="flex items-center flex-1 !p-5"
        body-classes="flex-1">
        <div class="flex">
          <div class="flex flex-1 space-x-6">
            <template
              v-for="data in aggregates"
            >
              <info-card
                v-if="mainGroup.includes(data.type)"
                :key="data.name"
                :loading="!$page.props.table?.aggregates"
                :data="data"
                class="flex-none"
              />
            </template>
          </div>
          <div class="relative flex pl-3 space-x-6">
            <div class="absolute left-0 w-px h-8 -translate-y-1/2 top-1/2 bg-bo-gray-200" />
            <template
              v-for="data in aggregates"
            >
              <info-card
                v-if="subGroup.includes(data.type)"
                :key="data.name"
                hide-icon
                :loading="!$page.props.table?.aggregates"
                :data="data"
                class="flex-none"
              />
            </template>
          </div>
        </div>
      </sm-card>
    </div>

    <sm-card no-padding>
      <sm-datatable
        loaded
        :sorts="sorts"
        :data="table">
        <template #columns>
          <Column
            v-for="(col, index) of tableFields"
            :key="col + '_' + index"
            :field="col"
            :header="$t(`reports.sold_products.columns.${col}`)"
            :sortable="sorts.allowed.find(data => data === col)">
            <template #body="slotProps">
              <template
                v-if="col === 'customer'">
                <span v-if="typeof(slotProps.data?.customer) === 'string'">{{ slotProps.data?.customer }}</span>
                <sm-avatar
                  v-else
                  :user="slotProps.data?.customer" />
              </template>
              <template v-else-if="col === 'sold'">
                {{ useDateFormat(slotProps.data?.sold, 'long') }}
              </template>
              <template v-else>
                {{ slotProps.data?.[col] }}
              </template>
            </template>
          </Column>
          <Column field="actions">
            <template #body="slotProps">
              <div
                class="flex justify-end space-x-1">
                <sm-button
                  v-if="slotProps.data?.permissions.canView"
                  v-tippy="$t('generic.buttons.view')"
                  preset="view-icon"
                  :href="slotProps.data?.routes.show" />
              </div>
            </template>
          </Column>
        </template>
      </sm-datatable>
    </sm-card>
  </div>
</template>

<script setup>
import { useDateFormat, useNumberFormat, usePriceFormat } from "@/inertia/composables/global";
import InfoCard from "@/inertia/pages/reports/_partials/InfoCard.vue";

const props = defineProps({
  table: { type: Object, required: false, default: () => {} },
  sorts: { type: Object, required: false, default: () => {} },
  aggregates: { type: Array, required: false, default: () => [] },
});

const mainGroup = ["cash", "card", "szep_card", "online", "balance"];
const subGroup = ["voucher", "gift_certificate", "correction", "transfer"];

const aggregates = computed(() => {
  return props.aggregates.map(aggregate => {
    return {
      ...aggregate,
      money: usePriceFormat(props.table?.aggregates[aggregate.type] ?? 0),
    };
  });
});

const tableFields = ["product_name", "customer", "amount", "unit_price", "weighted_avg_price", "payment_type", "sold", "cash_register", "warehouse"];

const stat = computed(() => {
  const total = props.table?.aggregates.total ?? 0;

  return {
    title: "Összbevétel",
    tooltip: "Az összbevétel a megadott szűrők alapján számolt teljes bevételi értéket mutatja.",
    amount: total,
    helper: "Ft",
  };
});
</script>
