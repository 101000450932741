<template>
  <div class="text-left">
    <sm-button
      color="secondary"
      :title="$t('customers.sales_modal.title')"
      hotkey="F2"
      @click="toggleModalVisibility" />
    <sm-button
      v-if="pagePermissions?.canManageParking && pageParking?.hasActiveParking"
      color="secondary"
      :title="$t('customers.sales_modal.parking_title')"
      class="ml-2"
      @click="toggleModalVisibility" />
    <sm-modal
      :modal-is-visible="modal"
      size="xl"
      :modal-title="$t('customers.sales_modal.title')"
      @toggleModalVisibility="toggleModalVisibility"
      @clickAway="storeCart"
      @closedWithX="storeCart"
    >
      <template #header>
        <template v-if="! pageSaleModal.activeCashRegister">
          <div class="w-full lg:w-3/12 sm-col flex items-center space-x-2">
            <sm-label
              :label="$t('customers.sales_modal.form.cash_register.label')"
              class="mb-0 justify-end"
            />
            <sm-select
              ref="cashRegisterInput"
              v-model="cashRegister"
              :options="cashRegisterList"
              custom-label="name"
              :placeholder="$t('customers.sales_modal.form.cash_register.placeholder')"
              class="!mb-0 w-full"
              @keypress.enter.prevent />
          </div>
        </template>
        <div v-else>
          <span class="font-semibold">{{ $t('customers.sales_modal.form.cash_register.label') }}:</span> {{ pageSaleModal.activeCashRegister.name }}
        </div>
      </template>
      <input
        v-if="customerId"
        type="hidden"
        name="customer"
        :value="customerId">
      <input
        type="hidden"
        name="cash_register_id"
        :value="cashRegister ? cashRegister.id : null">

      <div v-auto-animate>
        <sm-alert
          v-if="errorText !== ''"
          color="danger">
          {{ errorText }}
        </sm-alert>
      </div>

      <div class="product-list">
        <div class="sm-row">
          <div
            v-if="!customer"
            class="w-full sm-col">
            <div class="sm-form-group">
              <label class="sm-label">{{ $t('customers.sales_modal.form.selected_user.label') }}</label>
              <user-picker
                ref="customerInput"
                v-model="selectedUser"
                name="customerId"
                :placeholder="$t('customers.sales_modal.form.selected_user.placeholder')"
                no-hidden
                :append-to-body="false"
                @on-user-picker-close="onUserPickerClose" />
            </div>
          </div>

          <template v-if="customerId">
            <div class="w-full lg:w-6/12 sm-col">
              <div
                class="sm-form-group"
                :class="{ 'has-error': errors.product !== '' }">
                <label class="sm-label">{{ $t('customers.sales_modal.form.product.label') }}</label>
                <div class="sell-product-select">
                  <v-select
                    ref="productInput"
                    v-model="product.product"
                    :options="productList"
                    :placeholder="$t('customers.sales_modal.form.product.placeholder')"
                    :filterable="false"
                    error
                    @search="searchProduct"
                    @keypress.enter.prevent
                    @option:selected="focusInputAfterProduct"
                    @close="onClosed">
                    <template #no-options="{ search }">
                      {{ search.length < 2 ? $t('customers.sales_modal.form.product.empty.start') : $t('customers.sales_modal.form.product.empty.end') }}
                    </template>
                  </v-select>
                  <div v-auto-animate>
                    <div
                      v-if="$page.props.errors?.products || errors.product !== ''"
                      class="sm-feedback error">
                      {{ $page.props.errors?.products || errors.product }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="formRequiresAmount()"
              class="w-full lg:w-3/12 sm-col">
              <div
                class="sm-form-group"
                :class="{ 'has-error': errors.amount !== '' }">
                <div>
                  <sm-input
                    ref="amountInput"
                    v-model="product.amount"
                    :label="$t('customers.sales_modal.form.amount.label')"
                    type="number"
                    class="mb-0"
                    min="1"
                    @onEnter="addAndProductAfterAmount" />
                  <div v-auto-animate>
                    <div
                      v-if="errors.amount !== ''"
                      class="sm-feedback error">
                      {{ errors.amount }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="order-last lg:order-none sm-col lg:pt-label">
              <div class="sm-form-group">
                <sm-button
                  color="primary"
                  size="input"
                  :title="$t('customers.sales_modal.form.add_product')"
                  icon="check"
                  @click="addProduct" />
              </div>
            </div>

            <div
              v-if="product && formRequiresValidFrom()"
              class="w-full lg:w-6/12 sm-col">
              <sm-date-picker
                ref="validFromInput"
                name="valid_from"
                focus-today
                :label="$t('customers.sales_modal.form.valid_from.label')"
                :value="new Date()"
                @change="onValidFromChange"
                @onEnter="addAndProductFocus" />
            </div>

            <div
              v-if="product && formRequiresWarehouse()"
              class="w-full lg:w-3/12 sm-col">
              <div
                class="sm-form-group"
                :class="{ 'has-error': errors.warehouse !== '' }">
                <label class="sm-label">{{ $t('customers.sales_modal.form.warehouse.label') }}</label>
                <sm-select
                  ref="warehouseInput"
                  v-model="product.warehouse"
                  :options="warehouseList"
                  :placeholder="$t('customers.sales_modal.form.warehouse.placeholder')"
                  class="mb-0"
                  @keypress.enter.prevent
                  @onSelected="addAndProductFocus" />
                <div v-auto-animate>
                  <div
                    v-if="errors.warehouse !== ''"
                    class="sm-feedback error">
                    {{ errors.warehouse }}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>

      <sm-label :label="$t('customers.sales_modal.form.products_title')" />
      <div class="mb-6 overview-list">
        <div class="sm-table-responsive">
          <table class="sm-table sm-table-bordered sm-table-striped">
            <thead>
              <tr>
                <th>{{ $t('customers.sales_modal.table.label') }}</th>
                <th class="w-px">
                  {{ $t('customers.sales_modal.table.is_takeout') }}
                </th>
                <th class="w-1/4">
                  {{ $t('customers.sales_modal.table.amount') }}
                </th>
                <th class="w-1/4">
                  {{ $t('customers.sales_modal.table.price') }}
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              <template
                v-for="taxGroup of groupedProducts"
                :key="taxGroup">
                <template
                  v-for="(tableProduct, index) of taxGroup"
                  :key="index">
                  <tr>
                    <td style="vertical-align: middle;">
                      {{ tableProduct.product.label }}
                      <vue-feather
                        v-if="tableProduct.validFrom"
                        v-tippy="`${$t('customers.sales_modal.form.valid_from.tippy')} ${useDateFormat(tableProduct.validFrom)}`"
                        type="clock"
                        class="w-3 h-3 ml-1 translate-y-0.5" />
                    </td>
                    <td>
                      <div class="text-center">
                        <sm-checkbox
                          v-if="tableProduct.canBeTakenOut()"
                          v-model="tableProduct.isTakeout"
                          name="isTakeout"
                          text=""
                          class="mb-0 -mr-4"
                          @update:modelValue="handleTakeoutChange(tableProduct)" />
                        <span v-else>-</span>
                      </div>
                    </td>

                    <td style="vertical-align: middle;">
                      <div
                        v-if="pageSaleModal.permissions?.userCanSetCustomPrice"
                        class="flex items-center">
                        <sm-input
                          v-model="tableProduct.amount"
                          class="w-20 mb-0"
                          min="1"
                          type="number" />
                        <span class="mx-2 whitespace-nowrap">x</span>
                        <sm-input
                          v-model="tableProduct.price"
                          :disabled="tableProduct.priceIsLoading"
                          class="min-w-[90px] mb-0"
                          min="0"
                          type="number">
                          <template #append>
                            <template v-if="tableProduct.priceIsLoading">
                              <vue-feather
                                type="rotate-cw"
                                class="w-4 h-4 animate-spin" />
                            </template>
                            <template v-else>
                              <span>Ft</span>
                            </template>
                          </template>
                        </sm-input>
                      </div>
                      <span v-else>
                        {{ tableProduct.amount }} x {{ tableProduct.price.toLocaleString() }} Ft
                      </span>
                    </td>

                    <td
                      style="vertical-align: middle;"
                      class="whitespace-nowrap"
                    >
                      {{ tableProduct.total().toLocaleString() }} Ft
                      <span
                        v-tippy="{
                          allowHTML: true, content:
                            '<div class=\'text-left\'>'
                            + '<span class=\'whitespace-nowrap\'>' + $t('customers.sales_modal.table.original_price') + ': ' + tableProduct.priceAttributes.originalPrice + ' ' + 'Ft' + '</span><br>'
                            + '<span class=\'whitespace-nowrap\'>' + $t('customers.sales_modal.table.discount_percentage') + ': ' + tableProduct.priceAttributes.discountPercentage + '%'
                            + ' (' + tableProduct.priceAttributes.discountValue + ' ' + 'Ft' + ')' + '</span><br>'
                            + '<span class=\'whitespace-nowrap\'>' + $t('customers.sales_modal.table.old_price') + ': ' + tableProduct.priceAttributes.oldPrice + ' ' + 'Ft' + '</span>'
                            + '</-div>'
                        }"
                        class="pb-2">
                        <vue-feather
                          type="info"
                          class="w-4 h-4 translate-y-0.5 ml-1" />
                      </span>
                      <span
                        v-if="tableProduct.taxCategory"
                        class="pl-1">
                        {{ tableProduct.taxCategory.name }}
                      </span>
                    </td>

                    <td style="vertical-align: middle;">
                      <sm-delete-button
                        :name="`deleteFromCard_${tableProduct.id}`"
                        :translations="{
                          header: $t('generic.delete_modal_title'),
                          message: $t('Biztosan el akarod távolítani ezt a terméket?')
                        }"
                        @onDelete="removeProduct(tableProduct)" />
                      <input
                        type="hidden"
                        name="products[]"
                        :value="JSON.stringify(tableProduct)">
                    </td>
                  </tr>
                </template>
                <template v-if="taxGroup.length">
                  <tr
                    class="bg-opacity-10 sm-table-primary-light">
                    <td
                      colspan="3"
                      class="font-bold">
                      <span>{{ taxGroup[0]?.taxCategory ? taxGroup[0].taxCategory.percentage : 0.00 }}% {{ $t('customers.sales_modal.table.tax_sum') }}</span>
                    </td>
                    <td
                      colspan="2"
                      class="font-bold">
                      {{ (taxGroup.reduce((total, product) => total + product.total(), 0)).toLocaleString() }} Ft
                    </td>
                  </tr>
                  <tr class="!h-6">
                    <td
                      colspan="5"
                      class="!border-r-0 !border-l-0 relative">
                      <span class="block absolute top-0 w-[calc(100%+2px)] -left-px h-full bg-white" />
                    </td>
                  </tr>
                </template>
              </template>

              <template
                v-if="depositFees.length"
              >
                <template
                  v-for="depositFee in depositFees"
                  :key="depositFee.price"
                >
                  <tr>
                    <td style="vertical-align: middle;">
                      {{ $t('stock.deposit_fee') }}
                    </td>
                    <td>
                      <div class="text-center">
                        <span>-</span>
                      </div>
                    </td>
                    <td style="vertical-align: middle;">
                      <span>
                        {{ depositFeeAmountForPrice(depositFee.price) }} x {{ depositFee.price.toLocaleString() }} Ft
                      </span>
                    </td>
                    <td
                      style="vertical-align: middle;"
                      class="whitespace-nowrap"
                    >
                      {{ (depositFeeAmountForPrice(depositFee.price) * depositFee.price).toLocaleString() }} Ft
                    </td>
                    <td style="vertical-align: middle;" />
                  </tr>
                </template>
                <tr
                  class="bg-opacity-10 sm-table-primary-light">
                  <td
                    colspan="3"
                    class="font-bold">
                    <span>0.00 % {{ $t('customers.sales_modal.table.tax_sum') }}</span>
                  </td>
                  <td
                    colspan="2"
                    class="font-bold">
                    {{ (depositFees.reduce((total, fee) => total + (fee.price * depositFeeAmountForPrice(fee.price)), 0)).toLocaleString() }} Ft
                  </td>
                </tr>
                <tr class="!h-6">
                  <td
                    colspan="5"
                    class="!border-r-0 !border-l-0 relative">
                    <span class="block absolute top-0 w-[calc(100%+2px)] -left-px h-full bg-white" />
                  </td>
                </tr>
              </template>

              <tr
                v-if="pageSaleModal?.showClubServiceFeeField"
                class="font-bold bg-opacity-10 sm-table-primary-light">
                <td colspan="3">
                  {{ $t('customers.sales_modal.table.service_fee') }}
                </td>
                <td>
                  <div class="sm-input-group min-w-[80px]">
                    <sm-input
                      v-model="serviceFee"
                      type="number"
                      min="0"
                      name="service_fee"
                      class="px-2 py-1 mb-0">
                      <template #append>
                        %
                      </template>
                    </sm-input>
                  </div>
                </td>
                <td />
              </tr>
              <tr class="font-bold sm-table-primary">
                <td colspan="3">
                  {{ $t('customers.sales_modal.table.sum') }}
                </td>
                <td class="whitespace-nowrap">
                  {{ totalAmount?.toLocaleString() }} Ft
                </td>
                <td />
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <sm-input
        v-model="note"
        type="textarea"
        name="note"
        optional
        :label="$t('customers.sales_modal.form.note.label')"
        :placeholder="$t('customers.sales_modal.form.note.placeholder')"
        rows="2" />

      <div class="payment-mode">
        <sm-radio-group
          id="sell-product-payment-modes"
          v-model="paymentType"
          :label="$t('customers.sales_modal.payment_type')"
          name="payment-mode"
          :items="paymentTypes"
          button-group />
      </div>

      <template #footer>
        <sm-button
          :title="$t('generic.cancel')"
          @click="toggleModalVisibility" />
        <template
          v-if="cartFeatureEnabled"
        >
          <div v-tippy="!cashRegister ? $t('customers.sales_modal.form.cart_disabled_without_cash_register') : ''">
            <sm-button
              :title="$t('customers.sales_modal.form.add_to_cart')"
              :disabled="!cashRegister"
              @click="storeCartAndCloseModal"
            />
          </div>
          <sm-button
            v-if="false && pageSaleModal.activeCart"
            :title="$t('customers.sales_modal.form.clear_cart')"
            @click="clearCart"
          />
        </template>
        <sm-button
          id="sellProductSubmit"
          color="primary"
          :loading="form.processing"
          :disabled="products.length === 0 || paymentType === null"
          :title="$t('customers.sales_modal.form.sell')"
          @click="onSubmit" />
      </template>
    </sm-modal>
  </div>
</template>

<script>
import Product from "@/backoffice/modules/sales/SellProductForm/Product.js";
import { useDateFormat, useFormatSelect } from "@/inertia/composables/global";
import { router, useForm } from "@inertiajs/vue3";
import axios from "axios";
import { format } from "date-fns";
import hotkeys from "hotkeys-js";
import debounce from "lodash/debounce";
import { useToast } from "vue-toastification";

export default {
  provide() {
    return {
      form: this.form
    };
  },
  props: {
    searchProductRoute: { type: String, default: "" },
    priceCalculatorRoute: { type: String, default: "" },
    warehouses: { type: Array, default: null },
    activeWarehouse: { type: Object, default: null },
    removeProductConfirmationMessage: { type: String, default: "" },
    openCashRegisters: { type: Array, default: () => [] },
    userCanSelectCashRegister: { type: Boolean, default: false },
    customer: { type: [Number, String], default: null },
    triggerModal: { type: Boolean, required: false, default: false },
    pageParking: { type: Object, required: false, default: null },
    pageSaleModal: { type: Object, required: false, default: null },
    pagePermissions: { type: Object, required: false, default: null },
  },
  data() {
    const mappedWarehouses = this.warehouses.map(w => ({ ...w, value: w.id, label: w.name }));

    const products = (this.pageSaleModal.activeCart?.products ?? [])
      .map(p => Product.fromOld(p));

    return {
      modal: false,
      products: products,
      groupedProducts: products.length > 0 ? this.updateGroupedProductList(products) : [],
      productList: [],
      selectedUser: null,
      warehouseList: mappedWarehouses,
      cashRegisterList: this.openCashRegisters,
      product: new Product(null, 1),
      warehouse: this.activeWarehouse ? mappedWarehouses.find(w => w.id === this.activeWarehouse.id) : null,
      cashRegister: this.pageSaleModal.activeCashRegister ? this.openCashRegisters.find(cr => cr.id === this.pageSaleModal.activeCashRegister.id) : null,
      amount: 1,
      serviceFee: this.pageSaleModal.activeCart?.service_fee ?? 0,
      paymentType: this.pageSaleModal.activeCart?.payment_type ?? null,
      focusToday: false,

      abortController: null,
      error: false,
      errors: {
        product: "",
        amount: "",
        warehouse: "",
      },
      errorText: "",
      note: this.pageSaleModal.activeCart?.note ?? "",
    };
  },
  computed: {
    form() {
      return useForm({
        // _token: 'asd',
        // _modal_name: 'asd'
        products: this.products,
        customer: this.customerId,
        cash_register_id: this.cashRegister ? this.cashRegister.id : null,
        service_fee: this.serviceFee,
        payment_mode: this.paymentType,
        note: this.note
      });
    },
    totalAmount() {
      let total = this.products.reduce((sum, p) => sum + p.totalWithDepositFee(), 0);

      const serviceFeeAmount = Math.round(total * this.serviceFee) / 100;

      total += serviceFeeAmount;

      if (this.paymentType === "cash" || this.paymentType === "cash_eur") {
        total = Math.ceil(total) % 5 === 0 ? Math.ceil(total) : Math.round(total / 5) * 5;
      }

      return Math.round(total);
    },
    customerId() {
      return this.customer || (this.selectedUser ? this.selectedUser.value : null);
    },
    paymentTypes() {
      return useFormatSelect(this.pageSaleModal.paymentTypes);
    },
    depositFees() {
      const depositFees = [];

      this.products
        .forEach(product => {
          if (!product.hasDepositFee()) {
            return;
          }

          let depositFee = depositFees.find(df => df.price === product.depositFee);

          if (!depositFee) {
            depositFee = new Product({
              label: this.$t("stock.deposit_fee"),
            }, 0);

            depositFee.setPrice(product.depositFee);
            depositFee.setPriceAttributes(product.depositFee, 0, 0, 0);

            depositFees.push(depositFee);
          }
        });

      return depositFees;
    },
    cartFeatureEnabled() {
      return this.pageSaleModal.permissions.hasSalesCartFeature;
    },
  },
  watch: {
    modal(e) {
      if (e === true) {
        hotkeys.setScope("sell-product");
        router.reload({
          only: ["saleModal"],
          onSuccess: (page) => {
            const activeCart = page.props.saleModal.activeCart;

            const products = (activeCart?.products ?? [])
              .map(p => Product.fromOld(p));
            this.products = products;
            this.groupedProducts = products.length > 0 ? this.updateGroupedProductList(products) : [];

            this.serviceFee = activeCart?.service_fee ?? 0;
            this.paymentType = activeCart?.payment_type ?? null;
            this.note = activeCart?.note ?? "";
          },
        }, {
          preserveState: true,
        });
        this.$nextTick(() => {
          this.$refs.productInput ? this.focusElement("productInput") : null;
        });
      } else {
        hotkeys.setScope("global");
      }
    },
    triggerModal() {
      this.toggleModalVisibility();
    },
  },
  mounted() {
    hotkeys("f2", "global", (e) => {
      e.preventDefault();
      if (e.repeat) {
        return;
      } else {
        this.toggleModalVisibility();
      }
    });
    hotkeys("f1", "sell-product", (e) => {
      e.preventDefault();
      this.paymentType = "cash";
      this.$nextTick(() => {
        document.querySelector("#sellProductSubmit")?.focus();
      });
    });
    hotkeys("f2", "sell-product", (e) => {
      e.preventDefault();
      this.paymentType = "card";
      this.$nextTick(() => {
        document.querySelector("#sellProductSubmit")?.focus();
      });
    });
    hotkeys("f3", "sell-product", (e) => {
      e.preventDefault();
      this.paymentType = "szep_card";
      this.$nextTick(() => {
        document.querySelector("#sellProductSubmit")?.focus();
      });
    });
    hotkeys("f4", "sell-product", (e) => {
      e.preventDefault();
      this.paymentType = "balance";
      this.$nextTick(() => {
        document.querySelector("#sellProductSubmit")?.focus();
      });
    });
  },
  methods: {
    useDateFormat(date) {
      return useDateFormat(date);
    },
    toggleModalVisibility() {
      this.modal = !this.modal;
    },
    onUserPickerClose() {
      const globalSalesModal = document.getElementById("sales-sell-product-modal-global");
      if (globalSalesModal && globalSalesModal.classList.contains("sm-show")) {
        hotkeys("esc", "sell-product", () => {
          window.toggleSmModal("#sales-sell-product-modal-global", "close");
        });
      }
    },
    onClosed() {
      hotkeys("esc", "sell-product", () => {
        window.toggleSmModal("#sales-sell-product-modal", "close");
      });
    },
    async searchProduct(term, loading) {
      if (term.length >= 2) {
        loading && loading(true);
        this.doProductSearch(term, loading, this);
      }
    },
    doProductSearch: debounce(async (term, loading, vm) => {
      vm.abortController?.abort();
      vm.abortController = new AbortController();

      try {
        const res = await axios.get(vm.searchProductRoute, {
          params: {
            customer: vm.customerId,
            search: term
          },
          signal: vm.abortController.signal,
        });
        vm.productList = res.data.data;
      } catch (e) {
        if (!axios.isCancel(e)) {
          throw e;
        }
      } finally {
        loading && loading(false);
      }
    }, 350),
    addProduct() {
      const isTicket = this.product.isTicket();

      // If the product has not been selected, exit
      if (!this.product.hasProduct()) {
        this.errors.product = window.trans("customers.sales_modal.form.errors.no_product");
        return false;
      }

      // If the selected amount is less then 1, exit
      if (this.product.amount < 1) {
        this.errors.amount = window.trans("customers.sales_modal.form.errors.no_amount");
        return false;
      }

      // If the form requires a warehouse and one hasnt been selected, exit
      if (this.formRequiresWarehouse() && !this.product.hasWarehouse()) {
        this.errors.warehouse = window.trans("customers.sales_modal.form.errors.no_warehouse");
        return false;
      }

      // If the selected product is a ticket check the valid from value
      const dateRegex = /[0-9]{4}-[0-9]{2}-[0-9]{2}/;
      const dateIsCorrect = dateRegex.test(this.product.validFrom);
      if (isTicket && (!this.product.hasValidFrom() || !dateIsCorrect)) {
        if (this.$refs.validFromInput) {
          this.focusElement("validFromInput");
        }

        const toast = useToast();
        toast.error("A bérlet érvényessége hibásan van megadva!");
        return false;
      }

      // Check if the product was already added to the list
      let product = this.products.find(p => p.product.product_type === this.product.product_type && p.product.value === this.product.value);

      // Tickets can not be purchased in bulk
      if (product && !isTicket) {
        // TODO: how to handle when a product has a different warehouse
        //   than the current active warehouse?
        product.increaseAmount(this.product.amount);
      } else {
        product = this.product;

        if (! product.hasWarehouse()) {
          product.setWarehouse(this.activeWarehouse);
        }

        this.products.push(product);
      }

      this.loadPriceForProduct(product);

      this.resetSearchedProducts();

      return true;
    },
    removeProduct(product) {
      this.products = this.products.filter(p => p !== product);
      this.groupedProducts = this.updateGroupedProductList(this.products);
    },
    formRequiresWarehouse() {
      return !this.activeWarehouse && this.product.isStockProduct();
    },
    formRequiresValidFrom() {
      return this.product.isTicket();
    },
    formRequiresAmount() {
      if (!this.product.hasProduct()) {
        return false;
      }
      return !this.product.isTicket();
    },
    focusInputAfterProduct() {
      this.$nextTick(() => {
        this.errors.product = "";
        if (this.formRequiresWarehouse() && !this.product.hasWarehouse()) {
          this.focusElement("amountInput");
        } else if (this.formRequiresValidFrom()) {
          this.focusElement("validFromInput");
          if (!this.product.validFrom) {
            this.product.setValidFrom(format(new Date(), "yyyy-MM-dd"));
          }
        } else if (this.formRequiresAmount()) {
          this.focusElement("amountInput");
        }
      });
    },
    focusElement(el) {
      this.$nextTick(() => {
        if (el === "warehouseInput") {
          this.errors.product = "";
          this.errors.amount = "";
          this.$refs.warehouseInput.$el.querySelector(".vs__search").focus();
        } else if (el === "validFromInput") {
          this.focusToday = true;
          this.$refs.validFromInput.$el.querySelector(".sm-input-text").focus();
        } else if (el === "amountInput") {
          this.$refs.amountInput.$el.querySelector(".sm-input-text").focus();
        } else if (el === "productInput") {
          this.$refs.productInput.searchEl.focus();
        }
      });
    },
    resetSearchedProducts() {
      this.productList = [];
      this.product = new Product(null, 1);
    },
    onValidFromChange(date) {
      if (!isNaN(Date.parse(date))) {
        this.product.setValidFrom(useDateFormat(date, "form"));
        this.focusInputAfterProduct();
      }
    },
    async loadPriceForProduct(product) {
      product.markAsLoading();

      try {
        const response = await axios.get(this.priceCalculatorRoute, {
          params: {
            customer: this.customerId,
            product_id: product.product.value,
            product_type: product.product.product_type,
            is_takeout: product.isTakeout,
          }
        });
        const data = response.data.data;
        product.setPrice(data.price ?? 0);
        product.setPriceAttributes(
          data.price ?? 0,
          data.original_price ?? 0,
          data.discount_value ?? 0,
          data.discount_percentage ?? 0
        );
        product.setTaxCategory(data.tax_category);
        product.setDepositFee(data.deposit_fee);
        this.groupedProducts = this.updateGroupedProductList(this.products);
      } catch (e) {
        const toast = useToast();
        toast.error(e.response ? e.response.data.message : e.message);
      } finally {
        product.markAsLoading(false);
      }
    },
    onSubmit() {
      this.form
        .post(this.$page.props.routes.sellProduct, {
          only: ["tickets", "checkinModal", "flash", "errors", "customer"],
          onSuccess: () => {
            this.resetAll();
          }
        });
    },
    storeCart() {
      if (! this.cartFeatureEnabled) {
        return;
      }

      if (this.form.products.length === 0) {
        // No need to story anything if there are no products in the cart.
        return;
      }

      this.form.post(this.$page.props.routes.cart.store, {
        only: ["customer"],
      });
    },
    storeCartAndCloseModal() {
      this.storeCart();
      this.toggleModalVisibility();
    },
    clearCart() {
      if (! this.cartFeatureEnabled) {
        return;
      }

      this.form.delete(this.$page.props.routes.cart.destroy, {
        only: ["customer"],
        onSuccess: () => this.resetAll(),
      });
    },
    resetErrors() {
      this.errors.product = "";
      this.errors.warehouse = "";
      this.errors.amount = "";
    },
    addAndProductFocus() {
      this.errors.product = "";
      this.$nextTick(() => {
        this.addProduct();
        this.focusElement("productInput");
        this.resetErrors();
      });
    },
    addAndProductAfterAmount() {
      if (this.formRequiresWarehouse()) {
        this.resetErrors();
        this.focusElement("warehouseInput");
      } else {
        this.resetErrors();
        this.addProduct();
        this.focusElement("productInput");
      }
    },
    handleTakeoutChange(product) {
      this.loadPriceForProduct(product);
    },
    updateGroupedProductList(products) {
      return Object.groupBy(products, ({ taxCategory }) => taxCategory ? taxCategory?.percentage : 0.00);
    },
    depositFeeAmountForPrice(price) {
      return this.products
        .filter(p => p.hasDepositFee() && p.depositFee === price)
        .reduce((sum, p) => sum + parseInt(p.amount), 0);
    },
    resetAll() {
      this.form.reset();
      this.selectedUser = null;
      this.products = [];
      this.groupedProducts = [];
      this.paymentType = null;
      this.note = "";
      this.resetErrors();
      this.resetSearchedProducts();
      this.toggleModalVisibility();
    }
  }
};
</script>
