<template>
  <sm-input-wrapper ref="asyncSelectRoot">
    <template
      v-if="$slots.afterLabel"
      #afterLabel>
      <slot name="afterLabel" />
    </template>
    <template
      v-if="$slots.prepend"
      #prepend>
      <slot name="prepend" />
    </template>
    <template
      v-if="$slots.afterInput"
      #afterInput>
      <slot name="afterInput" />
    </template>
    <template
      v-if="$slots.append"
      #append>
      <slot name="append" />
    </template>
    <v-select
      ref="smSelect"
      :input-id="id"
      :options="selectOptions"
      :model-value="modelValue"
      :filterable="false"
      :reduce="reduceFn"
      :disabled="disabled"
      :placeholder="placeholder ? placeholder : $t('generic.start_search')"
      :taggable="false"
      :clearable="clearable"
      :multiple="multiple"
      @search="onSearch"
      @search:focus="onSearchFocus"
      @update:modelValue="onModelValueUpdated"
    >
      <template
        v-if="$slots['selected-option']"
        #selected-option="{id: innerId, label: innerLabel, data}">
        <slot
          :id="innerId"
          name="selected-option"
          :label="innerLabel"
          :data="data"
        />
      </template>
      <template
        v-if="$slots.option"
        #option="{id: innerId, label: innerLabel ,data}">
        <slot
          :id="innerId"
          name="option"
          :label="innerLabel"
          :data="data"
        />
      </template>
      <template #no-options>
        <p
          class="my-0">
          {{ $t("generic.no_result") }}
        </p>
      </template>
    </v-select>
  </sm-input-wrapper>
</template>

<script setup>
import SmInputWrapper from "@/inertia/components/forms/_layouts/SmInputWrapper.vue";
import { useDebounceFn } from "@vueuse/core";
import axios from "axios";

const selectOptions = ref([]);

const props = defineProps({
  name: { type: String, required: false, default: "" },
  id: { type: String, required: false, default: "" },
  label: { type: String, required: false, default: "" },
  route: { type: String, required: false, default: "" },
  modelValue: { type: [Object, String, Number], required: false, default: null },
  disabledIds: { type: Array, required: false, default: () => [] },
  disabled: { type: Boolean, required: false, default: false },
  placeholder: { type: String, required: false, default: "" },
  tooltip: { type: String, required: false, default: "" },
  help: { type: String, required: false, default: "" },
  error: { type: [Boolean, String], required: false, default: false },
  inline: { type: Boolean, required: false, default: false },
  half: { type: Boolean, required: false, default: false },
  required: { type: Boolean, required: false, default: false },
  multiple: { type: Boolean, required: false, default: false },
  clearable: { type: Boolean, required: false, default: true },
});

const emit = defineEmits(["update:modelValue", "optionSelected"]);

const onSearch = (search, loading) => {
  if (search.length) {
    loading?.(true);
    callSearchApi(search, loading);
  }
};

const callSearchApi = useDebounceFn(async (search, loading, paramModifier) => {
  const params = new URLSearchParams();

  if (search?.length) {
    params.set("search", search);
  }

  if (paramModifier) {
    paramModifier(params);
  }

  const url = buildUrl(props.route, params);

  try {
    const response = await axios.get(url);

    let options = response.data.data;

    if (props.disabledIds) {
      for (const id of props.disabledIds) {
        options = options.filter(data => data.id != id);
      }
    }

    selectOptions.value = options.map(data => ({
      id: data.id,
      label: data.title || data.name,
      data,
    }));
  } catch (e) {
    console.error(e);
  } finally {
    loading?.(false);
  }
}, 300);

const buildUrl = (baseUrl, params) => {
  const divider = baseUrl.includes("?") ? "&" : "?";

  const paramsQueryString = params.toString();

  if (paramsQueryString.length) {
    return `${baseUrl}${divider}${paramsQueryString}`;
  }

  return baseUrl;
};

const onSearchFocus = () => {
  // Call the API directly to avoid search checks.
  callSearchApi();
};

const onModelValueUpdated = (e) => {
  emit("update:modelValue", e);

  checkAndEmitOptionSelected(e);
};

const checkAndEmitOptionSelected = (e) => {
  if (Array.isArray(e)) {
    const arrayOption = [];
    e.map(data => {
      arrayOption.push(selectOptions.value?.find((option) => {
        return option.id === data;
      }));
    });
    if (arrayOption.length) {
      emit("optionSelected", arrayOption);
    }
  } else {
    const option = selectOptions.value?.find((option) => {
      return reduceFn(option) === e;
    });
    if (option) {
      emit("optionSelected", option);
    }
  }

};

onMounted(async () => {
  if (props.modelValue) {
    await callSearchApi(null, null, (params) => {
      if (Array.isArray(props.modelValue)) {
        for (const val of props.modelValue) {
          params.append("ids[]", val);
        }
      } else {
        params.set("ids[]", props.modelValue);
      }
    });

    checkAndEmitOptionSelected(props.modelValue);
  }
});

const reduceFn = (obj) => obj.id;
provide("props", props);
</script>
