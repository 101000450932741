<template>
  <div>
    <sm-button
      color="secondary"
      :title="$t('customers.actions.aycm_logging_in')"
      hotkey="F6"
      :loading="form.processing"
      @click="hasModal ? toggleModalVisibility() : onSubmit()" />
    <sm-modal
      :modal-is-visible="modal"
      modal-title="Olvasd be a vendég AYCM QR-kódját"
      close-button
      form
      @toggleModalVisibility="toggleModalVisibility">
      <img
        src="../../../../../../img/aycm_qr.png"
        class="w-[178px] mx-auto py-9"
        alt="">
      <form
        @submit.prevent="onSubmit()">
        <sm-input
          ref="aycmInput"
          v-model="form.qrCode"
          label="AYCM kód:"
        />
      </form>

      <sm-alert
        v-if="form.errors?.qrCode"
        color="danger">
        <p class="text-left">
          {{ form.errors?.qrCode }}
        </p>
      </sm-alert>

      <template #footer>
        <sm-button
          :title="$t('generic.cancel')"
          @click="toggleModalVisibility" />
        <sm-button
          color="primary"
          type="submit"
          :loading="form.processing"
          :title="$t('customers.actions.logging_in')"
          @click="onSubmit" />
      </template>
    </sm-modal>
  </div>
</template>

<script setup>
import { useForm, usePage } from "@inertiajs/vue3";
import { useDebounceFn } from "@vueuse/core";
import hotkeys from "hotkeys-js";

const form = useForm({
  qrCode: "",
});
const page = usePage();
const aycmInput = ref(null);

const hasModal = page.props.aycmQrCodeValidation;

const modal = ref(false);
const toggleModalVisibility = () => {
  modal.value = !modal.value;
  setTimeout(() => {
    if (modal.value) {
      aycmInput.value?.$el.querySelector(".sm-input-text").focus();
    }
  }, 100);
};


const onSubmit = useDebounceFn(() => {
  form.post(page.props.routes.checkInWithAYCM, {
    only: ["clubOpen", "customerCheckedIn", "hasAycmQuickCheckin", "checkinModal", "customer", "errors", "flash", "latestCheckinValidUntil"],
  });
}, 300);

onMounted(() => {
  hotkeys("f6", "global", (e) => {
    if (page.props.customerCheckedIn || form.processing) {
      return;
    }

    e.preventDefault();
    if (e.repeat) {
      return;
    } else {
      if (hasModal) {
        toggleModalVisibility();
      } else {
        onSubmit();
      }
    }
  });
});

</script>
