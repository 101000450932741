<template>
  <div>
    <In-Head :title="$t('sales.shifts.index.title')" />

    <sub-header :title="$t('sales.shifts.index.title')">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
    </sub-header>

    <sm-card no-padding>
      <sm-datatable
        loaded
        :sorts="sorts"
        :data="table">
        <template #columns>
          <Column
            v-for="(col, index) of tableFields"
            :key="col + '_' + index"
            :field="col"
            :header="$t(`sales.shifts.index.table.${col}`)"
            :sortable="sorts.allowed.find(data => data === col)">
            <template #body="slotProps">
              <template v-if="col === 'customer'">
                <sm-avatar :user="slotProps.data?.customer" />
              </template>
              <template v-else-if="col === 'shift'">
                {{ useDateFormat(slotProps.data?.from, 'long') }}
                <template v-if="slotProps.data">
                  <br>{{ useDateFormat(slotProps.data?.to, 'long') }}
                </template>
              </template>
              <template v-else-if="col === 'stored_cash'">
                <template v-if="slotProps.data?.closed">
                  {{ slotProps.data.stored_cash }}<br>
                  <span
                    v-if="slotProps.data?.cash_difference > 0"
                    class="text-bo-green">+{{ slotProps.data?.cash_difference_format }}</span>
                  <span
                    v-else-if="slotProps.data?.cash_difference < 0"
                    class="text-bo-red">{{ slotProps.data?.cash_difference_format }}</span>
                </template>
              </template>
              <template v-else-if="col === 'stored_card'">
                <template v-if="slotProps.data?.closed">
                  {{ slotProps.data.stored_card }}<br>
                  <span
                    v-if="slotProps.data?.card_difference > 0"
                    class="text-bo-green">+{{ slotProps.data?.card_difference_format }}</span>
                  <span
                    v-else-if="slotProps.data?.cash_difference < 0"
                    class="text-bo-red">{{ slotProps.data?.card_difference_format }}</span>
                </template>
              </template>
              <template v-else>
                {{ slotProps.data?.[col] }}
              </template>
            </template>
          </Column>
          <Column field="actions">
            <template #body="slotProps">
              <div
                class="flex justify-end space-x-1">
                <sm-button
                  v-tippy="$t('generic.details')"
                  preset="view-icon"
                  :href="slotProps.data?.routes.show" />
                <sm-button
                  v-if="slotProps.data?.closed && slotProps.data?.permissions.canEdit"
                  v-tippy="$t('generic.edit')"
                  icon="edit"
                  size="icon"
                  old-link
                  :href="slotProps.data?.routes.edit" />
                <sm-confirm-button
                  v-if="!slotProps.data?.closed && slotProps.data?.permissions.canClose"
                  v-tippy="$t('sales.shifts.index.close_shift')"
                  :confirm-title="$t('sales.shifts.index.close_shift_confirm')"
                  icon="key"
                  :name="`close_${slotProps.data?.id}`"
                  @onAccept="closeNavigation(slotProps?.data)" />
              </div>
            </template>
          </Column>
        </template>
      </sm-datatable>
    </sm-card>
  </div>
</template>

<script setup>
import { useDateFormat } from "@/inertia/composables/global";

defineProps({
  table: { type: Object, required: false, default: () => {} },
  sorts: { type: Object, required: false, default: () => {} },
});

const tableFields = ref(["id", "customer", "shift", "stored_cash", "stored_card", "cash_register"]);

const closeNavigation = (data) => {
  window.location.href = data?.routes.close;
};
</script>
