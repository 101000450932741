<template>
  <div class="text-left">
    <sm-button
      color="secondary"
      :title="$t('customers.club_data.title')"
      @click="toggleModalVisibility" />
    <sm-modal
      :modal-is-visible="modal"
      size="lg"
      :closeable="closeable"
      :modal-title="$t('customers.club_data.title')"
      @toggleModalVisibility="toggleModalVisibility">
      <sm-alert
        v-if="page.props.hasMissingFields"
        color="warning">
        {{ $t('customers.profile.missing_fields') }}
      </sm-alert>
      <form @submit.prevent="onSubmit()">
        <div class="grid grid-cols-2 gap-x-4">
          <div>
            <sm-input
              v-model="form.last_name"
              name="last_name"
              required
              :label="$t('customers.fields.lastname.label')"
            />
          </div>
          <div>
            <sm-input
              v-model="form.first_name"
              name="first_name"
              required
              :label="$t('customers.fields.firstname.label')"
            />
          </div>
          <div class="col-span-2">
            <sm-input
              v-model="form.email"
              name="email"
              required
              :label="$t('customers.fields.email.label')"
            />
          </div>
          <div>
            <sm-input
              v-model="form.phone"
              name="phone"
              required
              :label="$t('customers.fields.phone.label')"
            />
          </div>
          <div>
            <sm-input
              v-model="form.zip_code"
              name="zip_code"
              required
              :label="$t('customers.fields.zip_code.label')"
            />
          </div>
          <div>
            <sm-date-picker-2
              v-model="form.birth_date"
              name="birth_date"
              required
              :label="$t('customers.fields.birth_date.label')"
              :placeholder="$t('customers.fields.birth_date.placeholder', {date: useDateFormat(new Date())})"
            />
          </div>
          <div>
            <sm-select
              v-model="form.gender"
              name="gender"
              preset="gender"
              required
              :label="$t('customers.fields.gender.label')"
            />
          </div>

          <hr class="col-span-2">

          <div class="col-span-2">
            <sm-input
              v-model="form.nickname"
              name="nickname"
              :label="$t('customers.fields.nickname.label')"
            />
          </div>
          <div>
            <sm-checkbox
              v-model="form.custom_credit_limit"
              name="custom_credit_limit"
              :label="$t('customers.fields.custom_credit_limit.label')"
              :text="$t('generic.yes')"
            />
          </div>
          <div>
            <sm-input
              v-model="form.credit_limit"
              name="credit_limit"
              :disabled="!form.custom_credit_limit"
              :label="$t('customers.fields.credit_limit.label')"
              :help="$t('customers.fields.credit_limit.help', {money: page.props.customerEditModal?.globalCreditLimit})"
            />
          </div>
          <div class="col-span-2">
            <sm-input
              v-model="form.note"
              type="textarea"
              name="note"
              :label="$t('customers.fields.note.label')"
            />
          </div>
          <div class="col-span-2">
            <div v-if="useWebCamPhoto">
              <sm-label :label="$t('customers.fields.club_customer_profile_image.label')" />
              <div class="flex justify-center">
                <web-cam
                  ref="webCam"
                  @input="clubCustomerProfileImage = $event" />
              </div>
            </div>
            <sm-file
              v-else
              v-model="clubCustomerProfileImage"
              name="club_customer_profile_image"
              :label="$t('customers.fields.club_customer_profile_image.label')"
            />
            <sm-checkbox
              v-model="useWebCamPhoto"
              name="custom_credit_limuse_web_cam_photoit"
              :help="$t('customers.fields.use_web_cam_photo.help', {size: $page.props.customerEditModal?.avatarMaxSize, dimension: `${$page.props.customerEditModal?.avatarDimensions?.width} x ${$page.props.customerEditModal?.avatarDimensions?.height}`})"
              :text="$t('customers.fields.use_web_cam_photo.text')"
            />
            <div
              v-if="$page.props.customer.profilePicture"
              class="relative flex items-start justify-center mb-4">
              <img
                :src="$page.props.customer.profilePicture"
                class="max-w-full rounded">
              <sm-button
                color="danger"
                icon="trash-2"
                size="icon"
                class="absolute top-0 right-0 "
                @click="deleteProfilePicture" />
            </div>
          </div>
          <div class="col-span-2">
            <sm-async-select
              v-model="form.contacts"
              name="contacts"
              :label="$t('customers.fields.contacts.label')"
              multiple
              :route="$page.props.routes.searchContacts" />
          </div>
          <div class="col-span-2">
            <sm-async-select
              v-model="form.coaches"
              name="coaches"
              :label="$t('customers.fields.coaches.label')"
              multiple
              :route="$page.props.routes.searchCoaches" />
          </div>
          <div>
            <sm-checkbox
              v-model="form.newsletter_enabled"
              name="newsletter_enabled"
              :label="$t('customers.fields.newsletter_enabled.label')"
              :text="$t('generic.yes')"
            />
          </div>
          <div>
            <sm-select
              v-model="form.language"
              name="language"
              :options="languageOptions"
              :label="$t('customers.fields.language.label')"
            />
          </div>
        </div>
      </form>
      <template v-if="page.props.customerEditModal?.permissions.canBanUser">
        <sm-button
          v-if="!customerIsBanned"
          :title="$t('customers.club_data.ban_modal.button')"
          color="danger-outline"
          icon="slash"
          @click="confirmBan" />
        <sm-button
          v-if="customerIsBanned"
          :title="$t('customers.club_data.unban_modal.button')"
          color="success-outline"
          icon="thumbs-up"
          @click="confirmUnban" />
      </template>
      <template #footer>
        <div class="inline-flex items-center mb-2 mr-auto space-x-2 lg:mb-0">
          <sm-button
            v-if="!customerIsDeleted"
            :title="$t('customers.club_data.delete_modal.button')"
            color="danger-outline"
            icon="trash-2"
            @click="confirmDeleteProfile" />
          <form @submit.prevent="resetPassword">
            <sm-button
              :title="$t('customers.club_data.reset_password')"
              color="secondary"
              type="submit"
              :disabled="sendResetPasswordEmailForm.processing"
              @click="resetPassword" />
          </form>
        </div>
        <sm-button
          v-if="closeable"
          :title="$t('generic.cancel')"
          @click="toggleModalVisibility" />
        <sm-button
          color="primary"
          :loading="form.processing"
          :title="$t('generic.save')"
          @click="onSubmit" />
      </template>
    </sm-modal>
    <SmConfirmDialog group="confirmDeleteProfile" />
    <SmConfirmDialog group="confirmBan">
      <sm-input
        v-model="banReason"
        type="text"
        name="ban-reason"
        class="mb-0"
        :label="$t('customers.club_data.ban_modal.label')"
      />
    </SmConfirmDialog>
    <SmConfirmDialog group="confirmUnban" />
  </div>
</template>

<script setup>
import { useDateFormat, useFormatSelect } from "@/inertia/composables/global";
import { router, useForm, usePage } from "@inertiajs/vue3";
import { useDebounceFn } from "@vueuse/core";
import { useConfirm } from "primevue/useconfirm";
import { provide, watch } from "vue";

const page = usePage();
const modal = ref(false);
const closeable = ref(true);
const banReason = ref("");

const toggleModalVisibility = () => {
  modal.value = !modal.value;
};

const useWebCamPhoto = ref(false);
const clubCustomerProfileImage = ref(null);

const form = useForm({
  last_name: page.props.customer.lastName,
  first_name: page.props.customer.firstName,
  email: page.props.customer.email,
  phone: page.props.customer.phone,
  zip_code: page.props.customer.zipCode,
  birth_date: useDateFormat(page.props.customer.birthDate, "form"),
  gender: {id: page.props.customer.gender, label: window.trans(`genders.${page.props.customer.gender}`)},
  nickname: page.props.customer.nickname,
  club_customer_profile_image: null,
  custom_credit_limit: page.props.customer.hasCustomCreditLimit,
  credit_limit: page.props.customerEditModal?.customerCreditLimit || page.props.customerEditModal?.globalCreditLimit,
  note: page.props.customer.note,
  contacts: page.props.customerEditModal?.contacts,
  coaches: page.props.customerEditModal?.coaches,
  newsletter_enabled: page.props.customer.newsletterEnabled,
  language: page.props.customer.anguage,
});

const sendResetPasswordEmailForm = useForm({});

// reload customerEditModal prop when modal is visible
watch(modal, (e) => {
  if (e === true) {
    router.reload({ only: ["customer", "customerEditModal", "tags"] }, { preserveState: true });
    form.defaults({
      last_name: page.props.customer.lastName,
      first_name: page.props.customer.firstName,
      email: page.props.customer.email,
      phone: page.props.customer.phone,
      zip_code: page.props.customer.zipCode,
      birth_date: useDateFormat(page.props.customer.birthDate, "form"),
      gender: {id: page.props.customer.gender, label: window.trans(`genders.${page.props.customer.gender}`)},
      nickname: page.props.customer.nickname,
      club_customer_profile_image: null,
      custom_credit_limit: page.props.customer.hasCustomCreditLimit,
      credit_limit: page.props.customerEditModal?.customerCreditLimit || page.props.customerEditModal?.globalCreditLimit,
      note: page.props.customer.note,
      contacts: page.props.customerEditModal?.contacts,
      coaches: page.props.customerEditModal?.coaches,
      newsletter_enabled: page.props.customer.newsletterEnabled,
      language: page.props.customer.anguage,
    });
  }
});

const languageOptions = ref([]);

// reload form with customerEditModal prop data when its available
watch(page, (e) => {
  if (e.props.customerEditModal) {
    languageOptions.value = useFormatSelect(page.props.customerEditModal?.languages);
    form.defaults("contacts", useFormatSelect(page.props.customerEditModal?.contacts));
    form.defaults("coaches", useFormatSelect(page.props.customerEditModal?.coaches));
    form.defaults("language", languageOptions.value.find(data => data.id === page.props.customer.language));
    form.defaults("credit_limit", page.props.customerEditModal?.customerCreditLimit || page.props.customerEditModal?.globalCreditLimit);

    if (page.props.missingFields) {
      form.setError(page.props.missingFields);
    } else {
      form.reset();
    }
  }
});

provide("form", form);

const onSubmit = () => {
  let profilePicture = null;
  if (clubCustomerProfileImage.value && typeof clubCustomerProfileImage.value === "string") {
    const [metadata, content] = clubCustomerProfileImage.value.split(",");
    const mime = metadata.match(/:(.*?);/)[1];
    const binary = atob(content);
    const dataArray = new Uint8Array(binary.length);

    for(let i = 0; i < binary.length; i++) {
      dataArray[i] = binary.charCodeAt(i);
    }

    profilePicture = new File([dataArray], "profile_picture", {type: mime});
  } else {
    profilePicture = clubCustomerProfileImage?.value;
  }

  const request = form
    .transform((data) => {
      return {
        ...data,
        birth_date: data.birth_date ? useDateFormat(data.birth_date, "form") : null,
        gender: data.gender?.id,
        language: data.language?.id,
        contacts: data.contacts? Object.values(data.contacts).map(entry => parseInt(entry.id)) : null,
        coaches: data.coaches? Object.values(data.coaches).map(entry => parseInt(entry.id)) : null,
        club_customer_profile_image: profilePicture,
        _method: "patch",
      };
    });

  if (profilePicture) {
    request.post(page.props.routes.customerUpdate, {
      only: ["customer", "customerEditModal", "tags", "errors", "flash", "tickets", "appointments", "missingFields"],
      onSuccess: () => {
        toggleModalVisibility();
        closeable.value = true;
      },
    });
  } else {
    request.patch(page.props.routes.customerUpdate, {
      only: ["customer", "customerEditModal", "tags", "errors", "flash", "tickets", "appointments", "missingFields"],
      onSuccess: () => {
        toggleModalVisibility();
        closeable.value = true;
      },
    });
  }
};

const confirm = useConfirm();

const deleteProfilePicture = () => {
  useForm({}).delete(page.props.routes.customerDeleteProfilePicture);
};

const deleteProfile = () => {
  useForm({})
    .delete(page.props.routes.deleteCustomer, {
      onSuccess: () => {
        toggleModalVisibility();
        closeable.value = true;
      },
    });
};

const confirmDeleteProfile = () => {
  confirm.require({
    group: "confirmDeleteProfile",
    message: `${window.trans("customers.club_data.delete_modal.message")}`,
    accept: () => {
      deleteProfile();
    }
  });
};

const banCustomer = () => {
  useForm({ reason: banReason.value })
    .post(page.props.routes.banCustomer, {
      onSuccess: () => {
        toggleModalVisibility();
        closeable.value = true;
        banReason.value = "";
      },
    });
};

const confirmBan = () => {
  confirm.require({
    group: "confirmBan",
    message: `${window.trans("customers.club_data.ban_modal.message")}`,
    accept: () => {
      banCustomer();
    }
  });
};

const unbanCustomer = () => {
  useForm({})
    .post(page.props.routes.unbanCustomer, {
      onSuccess: () => {
        toggleModalVisibility();
        closeable.value = true;
      },
    });
};

const confirmUnban = () => {
  confirm.require({
    group: "confirmUnban",
    message: `${window.trans("customers.club_data.unban_modal.message")}`,
    accept: () => {
      unbanCustomer();
    }
  });
};

const resetPassword = useDebounceFn(() => {
  sendResetPasswordEmailForm.post(page.props.routes.resetPassword);
}, 250);

const customerIsDeleted = computed(() => {
  return page.props.customer.deletedAt !== null;
});

const customerIsBanned = computed(() => {
  return page.props.customer.isBanned;
});

// open modal when no club data is available
onMounted(() => {
  if (page.props.hasMissingFields) {
    closeable.value = false;
    modal.value = true;
  }
});
</script>
