<template>
  <div>
    <In-Head :title="$t('customers.banned_users.index.title')" />

    <sub-header :title="$t('customers.banned_users.index.title')">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
    </sub-header>

    <sm-card no-padding>
      <sm-datatable
        :data="$page.props.table">
        <template #columns>
          <Column
            v-for="(col, index) of tableFields"
            :key="col + '_' + index"
            :field="col"
            :header="$t(`customers.banned_users.table.${col}`)">
            <template #body="slotProps">
              <template v-if="col === 'user'">
                <sm-avatar
                  v-if="!slotProps.data?.user.deleted"
                  :user="slotProps.data?.user"
                  type="link" />
                <span v-else>
                  {{ slotProps.data?.user.name }}
                </span>
              </template>
              <template v-else>
                {{ slotProps.data?.[col] }}
              </template>
            </template>
          </Column>
          <Column field="actions">
            <template #body="slotProps">
              <div
                class="flex justify-end space-x-1">
                <sm-delete-button
                  v-if="slotProps.data?.permissions.destroy"
                  :route="slotProps.data?.routes.destroy"
                  name="deleteBannedUser"
                />
              </div>
            </template>
          </Column>
        </template>
      </sm-datatable>
    </sm-card>
  </div>
</template>

<script setup>
const tableFields = ref([
  "user", "reason", "created_by", "created_at",
]);
</script>
